<template>
  <v-dialog v-model="dateDialog" width="1080" scrollable persistent>
    <v-card class="popup add_store_popup">
      <v-card-title> <h3 class="page-title-bar"><i class="ico ico-lcd-list"></i>{{$t('Schedule Calendar')}}</h3></v-card-title>
        <v-card-text class="pa-0">
          <v-layout column class="mt-4">
            <v-row no-gutters>
              <v-col cols="5">
                <vc-calendar
                  ref="calendar"
                  :attributes="attributes"
                  :min-date="new Date()"
                  is-expanded
                  v-model="today"

                  @update:from-page="dateChange"
                >
                  <template v-slot:day-content="{ day }">
                    <!-- <div class="flex flex-col h-full z-10 overflow-hidden">
                      <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                      <div class="flex-grow overflow-y-auto overflow-x-auto">
                        <p
                          v-for="attr in attributes"
                          :key="attr.key"
                          class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                          :class="attr.customData.class"
                        >
                          {{ attr.customData.title }}
                        </p>
                      </div>
                    </div> -->
                    <div class="h-55">
                      <div :class="isAttributes(day) ? getClass(day) : 'calendar-col'">
                        <div class="calendar-item" @click="clickItem(day)">
                          <span>{{ day.day }} </span>
                        </div>
                      </div>
                    </div>
                  </template>

                </vc-calendar>
              </v-col>

              <v-col cols="7" style="margin-left: 8%; max-width: calc(58.3333333333% - 8%);">
                <v-layout column class="mb-4">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <dl class="playlist-detail-info">
                        <dt>{{$t('Title')}}</dt>
                        <dd>{{ playlistData.customData.title }}</dd>
                      </dl>
                    </v-col>
                    <v-col cols="6">
                      <dl class="playlist-detail-info">
                        <dt>{{$t('Model')}}</dt>
                        <dd>{{ playlistData.customData.model }}</dd>
                      </dl>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <dl class="playlist-detail-info">
                        <dt>{{$t('Category')}}</dt>
                        <dd>{{ playlistData.customData.category }}</dd>
                      </dl>
                    </v-col>
                    <v-col cols="6">
                      <dl class="playlist-detail-info">
                        <dt>{{$t('Time')}}</dt>
                        <dd>{{ getTime }}</dd>
                      </dl>
                    </v-col>
                  </v-row>
                </v-layout>
                <div class="playlist-group-preview">
                  <v-card flat class="video-preview">
                    <v-card-title>{{$t('PREVIEW')}}</v-card-title>
                    <v-card-text class="text-center pb-10 pt-9">
                      <div class="time-slider-preview pb-9 mt-5">
                        <v-btn icon @click="prev"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <div>
                          <p class="text-subtitle-2 font-weight-bold">{{ getTime ? getTime : $t('No Data') }}</p>
                          <!-- <p class="font-weight-bold">{{ contents ? contents[index].media_name : 'No Data' }} </p> -->
                        </div>
                        <v-btn icon @click="next"><v-icon>mdi-chevron-right</v-icon></v-btn>
                      </div>
                      <div class="d-flex flex-row" v-if="isSelect">
                        <div class="d-flex" v-for="(item, index) in contents.division" :key="index" :style=" { width: item+'%' }">
                          <v-carousel
                            tag="div"
                            cycle
                            interval="2500"
                            hide-delimiter-background
                            hide-delimiters
                            :height="playlistData.customData.model === '23' ? 39.5 : 135"
                            :show-arrows="false"
                          >
                            <v-carousel-item
                            v-for="(item, index) in JSON.parse(contents[index][0].still_shot)"
                            :key="index"
                            :src="srcURL + item"
                            reverse-transition="fade-transition"
                            transition="fade-transition">
                            </v-carousel-item>
                          </v-carousel>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="closeSchedule" class="btn">{{$t('Close')}}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus'
import dicamoApi from '@/lcd/plugins/dicamoApi'

export default {
  props: {
    showScheduleDisabled: Boolean,
    user: Object
  },
  data () {
    return {
      srcURL: dicamoApi.requests.thumbnail.url,
      scheduleData: [],
      attributes: [],
      playlistData: {
        customData: {
          playlist_set_title: '',
          model: '',
          cateogry: '',
          playlist: []
        }
      },
      playlistIndex: 0,
      isSelect: false,
      today: new Date(),
      dateDialog: false,
      videoPlay: 0,
      form: {
        month: 0,
        year: 0,
        idx_lcd: 0
      },
      test: [] // clickItem 에서 잘못들어오는 데이터 확인용 변수 > 릴리즈 버전에서는 삭제하면 됨.
    }
  },
  computed: {
    getTime () {
      let time = ''
      if (this.playlistData.customData.playlist.length > 0) {
        time = this.playlistData.customData.playlist[this.playlistIndex].text
      }
      return time
    },
    contents () {
      return this.dateDialog ? this.playlistData.customData.playlist.length > 0 ? this.playlistData.customData.playlist[this.playlistIndex].division : [] : []
    },
    playlistCount () {
      return this.dateDialog ? this.playlistData.customData.playlist.length - 1 : 0
    }
  },
  mounted () {
    EventBus.$on('showScheduleDialog', (index) => {
      this.form.idx_lcd = index
      this.dateDialog = true
      this.getScheduleData()
    })
  },
  methods: {
    setScheduleData (playlist) {
      this.attributes = []
      let startDay = ''
      let endDay = ''
      let singleDay = ''
      let key = 0
      this.test = []
      playlist.forEach((item) => {
        if (item.view_class === 'start') {
          startDay = this.getDay(item.a_date)
        } else if (item.view_class === 'single') {
          singleDay = item.a_date
          this.attributes.push({
            key: key,
            customData: {
              isActive: false,
              isSingle: true,
              title: item.title,
              model: item.device_type,
              category: item.category,
              playlist: JSON.parse(item.playlist_set)
            },
            // 수정 완료 Date 타입으로 변경해야되면 추후에 수정해야될 수도 있음.
            dates: { start: singleDay, end: singleDay }

          })
          // this.test.push({
          //   isSingle: true,
          //   view_class: item.view_class,
          //   date: item.a_date
          // })
          key++
        } else if (item.view_class === 'end') {
          endDay = this.getDay(item.a_date)
          this.attributes.push({
            key: key,
            customData: {
              isActive: false,
              isSingle: startDay === '',
              title: item.title,
              model: item.device_type,
              category: item.category,
              playlist: JSON.parse(item.playlist_set)
            },
            dates: { start: startDay === '' ? (this.form.year + '-' + this.form.month + '-' + endDay) : (this.form.year + '-' + this.form.month + '-' + startDay), end: (this.form.year + '-' + this.form.month + '-' + endDay) }
          })
          // this.test.push({
          //   isSingle: startDay === '' || endDay === '',
          //   view_class: item.view_class,
          //   date: item.a_date
          // })
          startDay = ''
          key++
        }
      })
    },
    getDay (date) {
      const day = date.split('-')
      return day[day.length - 1]
    },
    dateChange (item) {
      this.playlistIndex = 0
      this.form.month = item.month
      this.form.year = item.year
      this.playlistData = {
        customData: {
          title: '',
          model: '',
          cateogry: '',
          playlist: []
        }
      }

      this.getScheduleData()
    },
    getScheduleData () {
      const data = new FormData()
      if (commons.isNull(this.form.year) || this.form.year === 0) this.form.year = new Date().getFullYear()
      if (commons.isNull(this.form.month) || this.form.month === 0) this.form.month = new Date().getMonth() + 1

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('idx_lcd', this.form.idx_lcd)
      data.append('year', this.form.year)
      data.append('month', this.form.month)
      data.append('type', 'schedule')
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getScheduleLMappingist.method,
          dicamoApi.requests.getScheduleLMappingist.url,
          data
        )
        .then(res => {
          this.scheduleData = res.data.data
          this.setScheduleData(this.scheduleData)
        })
        .catch(error => {
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any lcd schdule. error: ${error}`)
        })
    },
    closeSchedule () {
      this.playlistIndex = 0
      this.dateDialog = false
      this.isSelect = false
      this.playlistIndex = 0
      this.attributes = []
      this.playlistData = {
        customData: {
          title: '',
          model: '',
          cateogry: '',
          playlist: []
        }
      }
      this.form = {
        month: 0,
        year: 0,
        idx_lcd: 0
      }
      this.playlistIndex = 0
      this.$emit('fireResetLcd')
    },
    clickItem (day) {
      this.playlistIndex = 0
      let playlist = {
        customData: {
          title: '',
          model: '',
          cateogry: '',
          playlist: []
        }
      }
      if (day.attributes.length === 0) {
        this.attributes.forEach(item => {
          item.customData.isActive = false
        })
        this.playlistData = playlist
        return
      }
      this.isSelect = true
      this.attributes.forEach(item => {
        if (day.attributes[0].key !== item.key) {
          item.customData.isActive = false
        } else {
          if (item.customData.isActive) item.customData.isActive = false
          else item.customData.isActive = true
        }
        // item.customDAta.isActive = false
      })
      // if (day.attributes.length === 0) return

      // if (day.attributes[0].customData.isActive) {
      //   day.attributes[0].customData.isActive = false
      // }

      // console.log(day.attributes[0])
      day.attributes[0].customData.isActive = true
      playlist = day.attributes[0]
      this.playlistData = playlist
      // let playlist = []
      // this.isSelect = true
      // this.attributes.forEach(item => {
      //   console.log(item)
      //   if (item.customData.isActive) {
      //     item.customData.isActive = false
      //     // return null
      //   }

      //   if (commons.isNull(item.dates)) {
      //     console.log(item)
      //     if (item.date.getDate() === day.getDate()) {
      //       item.customData.isActive = true
      //       playlist = item
      //       return
      //     }
      //   }

      //   if (commons.isNull(item.date)) {
      //     if (item.dates.end.getDate() >= day.getDate() && day.getDate() >= item.dates.start.getDate()) {
      //       item.customData.isActive = true
      //       playlist = item
      //     }
      //   }

      //   // else if (item.dates.end.getDate() >= day.getDate() && day.getDate() >= item.dates.start.getDate()) {
      //   //   this.isSelect = true
      //   //   item.customData.isActive = true
      //   //   playlist = item
      //   // } else {
      //   //   item.customData.isActive = false
      //   // }
      //   // if (item.dates.end.getDate() >= day.getDate() && day.getDate() >= item.dates.start.getDate()) {
      //   // if (item.dates.end.getDate() === item.dates.start.getDate() === day.getDate()) {
      //   //   item.customData.isActive = true
      //   //   playlist = item
      //   // }
      // })
      // this.playlistData = playlist
    },
    isAttributes (day) {
      // console.log(commons.isNull(!day.attributes) ? day.attributes.length > 0 : false)
      return commons.isNull(!day.attributes) ? day.attributes.length > 0 : false
    },
    // isActive (day) {
    //   let isActive = false
    //   if (commons.isNull(!day.attributes)) {
    //     isActive = day.attributes.length > 0 ? day.attributes[0].customData.isActive : false
    //   }
    //   return isActive
    // },
    getClass (day) {
      let defaultClass = 'calendar-col calendar-date-selected '
      if (day.attributes[0].customData.isActive) defaultClass += 'calendar-date-active '
      if (day.attributes[0].customData.isSingle) return defaultClass + 'calendar-date--today-date'
      // console.log(day.attributes[0])

      // if (commons.isNull(day.attributes[0].dates[0])) console.log(day)

      // if (day.attributes[0].date.getDate() === day.date.getDate()) return defaultClass + 'calendar-date--today-date'
      // if ()
      if (day.attributes[0].dates[0].start.getDate() === day.date.getDate()) return defaultClass + 'calendar-date--first-date'
      else if (day.attributes[0].dates[0].end.getDate() === day.date.getDate()) return defaultClass + 'calendar-date--last-date'
      else return defaultClass
    },
    next () {
      if (this.playlistIndex < this.playlistCount) {
        this.playlistIndex++
      } else if (this.playlistIndex === this.playlistCount) {
        this.playlistIndex = 0
      }
    },
    prev () {
      if (this.playlistIndex === 0) {
        this.playlistIndex = this.playlistCount
      } else if (this.playlistIndex <= this.playlistCount) {
        this.playlistIndex--
      }
    }
  }
}
</script>
<style scoped>
::v-deep .v-image > div {
  background-size: 100% 100%;
}
.vc-container {
  border: none;
}
.h-55 {
  width: 100%;
  height: 55px;
  display: flex;
}

::v-deep div.vc-arrows-container {
  justify-content: space-around;
}

::v-deep .calendar-col {
  width: 100%;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
