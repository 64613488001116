<template>
  <v-dialog v-model="registDialog" width="512" class="rounded-0" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="btnDisabledRegistration"
        v-bind="attrs"
        v-on="on"
        text
        class="btn"
        >{{ $t('Registration') }}
      </v-btn>
    </template>
    <v-card class="popup add_store_popup">
      <v-card-title><h3 class="page-title-bar" style="margin-bottom: 0px;"><i class="ico ico-lcd-list"></i>{{$t('Registration')}}</h3></v-card-title>
      <v-card-text class="px-9" style="padding:0!important">
        <v-row no-gutters>
          <v-col cols="4" class="mt-10">
              <!-- <label>Model <span class="text-danger">*</span></label> -->
            <p style="font-size: 12px;">
                      {{$t('Model')}}
              <span class="redbold">*</span>
            </p>
          </v-col>
          <v-col cols="7" class="mt-10">
            <v-autocomplete
              v-model="form.model"
              :items="defaultModel"
              class="form-select pt-0"
              outlined
              dense
              hide-details>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="mt-10">
            <!-- <label>UUID <span class="text-danger">*</span></label> -->
            <p style="font-size: 12px;">
                      {{$t('UUID')}}
              <span class="redbold">*</span>
            </p>
          </v-col>
          <v-col cols="7" class="mt-10">
            <v-text-field
              v-model="form.uuid"
              :placeholder="$t('Input UUID')"
              class="form-input pt-0"
              outlined
              dense
              hide-details
              clearable>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="mt-10">
            <!-- <label>MAC Address <span class="text-danger">*</span></label> -->
            <p style="font-size: 12px;">
                      {{$t('MAC Address')}}
              <span class="redbold">*</span>
            </p>
          </v-col>
          <v-col cols="7" class="mt-10">
            <v-text-field
              v-model="form.mac"
              :placeholder="$t('Input MAC Address')"
              class="form-input pt-0"
              @input="macCheck"
              @change="changeMac"
              outlined
              dense
              hide-details
              clearable>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="mt-10">
            <!-- <label>Nickname <span class="text-danger">*</span></label> -->
            <p style="font-size: 12px;">
                      {{$t('Nickname')}}
              <span class="redbold">*</span>
            </p>
          </v-col>
          <v-col cols="7" class="mt-10">
            <v-text-field
              v-model="form.nickname"
              :placeholder="$t('Input Nickname')"
              class="form-input pt-0"
              outlined
              dense
              hide-details
              clearable>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon class="btn" :disabled="form.model===''|| form.uuid ==='' || form.mac.trim().length < 17 ||form.nickname===''" @click="insertLcdList">{{$t('Register')}}</v-btn>
        <v-btn text icon @click="registDialog = false" class="btn">{{$t('Close')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus'
import dicamoApi from '@/lcd/plugins/dicamoApi'

export default {
  props: {
    btnDisabledRegistration: Boolean,
    user: Object
  },
  data () {
    return {
      registDialog: false,
      form: {
        model: 23,
        uuid: '',
        nickname: '',
        mac: ''
      }
    }
  },
  methods: {
    macCheck (event) {
      this.form.mac = event.replaceAll(':', '').toUpperCase().trim()
    },
    changeMac (event) {
      let str = ''
      if (event.length > 12) event = event.slice(0, 12)
      for (let i = 0; i <= event.length; i++) {
        if (i % 2 === 0 && i !== 0 && i !== event.length) {
          str = str + event.slice(i - 1, i) + ':'
        } else {
          str = str + event.slice(i - 1, i)
        }
      }
      this.form.mac = str
    },
    insertLcdList () {
      const lcdList = new FormData()

      // let mac = ''
      // let uuid = ''

      // let macAddr = String(mac).toUpperCase().split(':')
      // macAddr = macAddr.join('')
      // macAddr = macAddr.replace(/(.{2})/g, '$1:')
      // const regexMac = /^([0-9a-fA-F]{2}[:.-]?){5}[0-9a-fA-F]{2}$/g
      // mac = macAddr.split(regexMac)
      // console.log(regexMac.test(mac))

      // let _uuid = String(uuid).toUpperCase().split('-')
      // _uuid = _uuid.join('')
      // _uuid = _uuid.replace(/(.{2})/g, '$1:')
      // const regexUuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
      // uuid = _uuid.split(regexUuid)
      // console.log(regexUuid.test(uuid))

      lcdList.append('company', this.$store.state.auth.user.company)
      lcdList.append('store', this.$store.state.dataStore.selectedStore.code)
      lcdList.append('device_type', this.form.model)
      lcdList.append('nickname', this.form.nickname)
      lcdList.append('uuid', String(this.form.uuid).trim())
      lcdList.append('mac', String(this.form.mac).trim())
      lcdList.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.postLcdList.method,
          dicamoApi.requests.postLcdList.url,
          lcdList
        )
        .then(res => {
          if (res.data.result) {
            EventBus.$emit('messageAlert', res.data.message === '' ? this.$t('SUCCESS') : this.$t(res.data.message))
            this.$emit('fireResetLcd')
            this.closeRegist()
          } else {
            EventBus.$emit('messageAlert', this.$t(res.data.message))
          }
        })
        .catch(error => {
          console.debug(`Could not find any lcdList. error: ${error}`)
        })
    },
    closeRegist () {
      this.form = {
        model: 23,
        uuid: '',
        nickname: '',
        mac: ''
      }
      this.registDialog = false
    }
  },
  computed: {
    defaultModel () {
      return [
        {
          text: this.$t('Newton Touch 23"'),
          abbr: 'Newton Touch 23"',
          originalText: 'Newton Touch 23"',
          value: 23
        },
        {
          text: this.$t('Newton Touch 29"'),
          abbr: 'Newton Touch 29"',
          originalText: 'Newton Touch 29"',
          value: 29
        },
        {
          text: this.$t('FHD Box'),
          abbr: 'FHD"',
          originalText: 'FHD"',
          value: 'FHD'
        },
        {
          text: this.$t('Tablet'),
          abbr: 'Tablet"',
          originalText: 'Tablet"',
          value: 'Tablet'
        }
      ]
    }
  }
}
</script>

<style scoped>
.redbold {
  color: red;
  font-weight: bold;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
