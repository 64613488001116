var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "900", scrollable: "", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _vm.btype === "assign"
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "btn btn-danger ml-3",
                          attrs: { disabled: _vm.btnDisabledAssign, text: "" }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(_vm._s(_vm.$t("Product Assign")))]
                  )
                : _vm._e(),
              _vm.btype === "edit"
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "btn",
                          attrs: { disabled: _vm.btnDisabledAssign, text: "" }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(_vm._s(_vm.$t("Edit Assign Info")))]
                  )
                : _vm._e()
            ]
          }
        }
      ]),
      model: {
        value: _vm.assignDialog,
        callback: function($$v) {
          _vm.assignDialog = $$v
        },
        expression: "assignDialog"
      }
    },
    [
      _c("v-card", { staticClass: "popup add_store_popup" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("v-card-title", [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-lcd-list" }),
                _vm._v(_vm._s(_vm.$t("Product Assign")))
              ])
            ]),
            _c("div", { staticClass: "ml-auto" }, [
              _c("ul", { staticClass: "step-progressbar" }, [
                _c("li", { class: _vm.stepClass(1) }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Select"))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("Product")))
                  ])
                ]),
                _c("li", { class: _vm.stepClass(2) }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Select"))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("Template")))
                  ])
                ]),
                _c("li", { class: _vm.stepClass(3) }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Assign"))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("Complete")))
                  ])
                ])
              ])
            ])
          ],
          1
        ),
        _vm.step === 1
          ? _c(
              "div",
              [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-4", staticStyle: { width: "70%" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("v-select", {
                                  staticClass: "form-input in-btn slot-visible",
                                  attrs: {
                                    label: _vm.$t("Product Type"),
                                    items: ["Product Name", "Product ID"],
                                    outlined: "",
                                    dense: "",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.searchProduct.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.searchProduct, "type", $$v)
                                    },
                                    expression: "searchProduct.type"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "8" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "form-input in-btn slot-visible",
                                  attrs: {
                                    label: _vm.$t("Product List"),
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    clearable: ""
                                  },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.searchAnchors("Product")
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { text: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.searchAnchors(
                                                      "Product"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/img/btn-search.jpg"),
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    2374344358
                                  ),
                                  model: {
                                    value: _vm.searchProduct.value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.searchProduct, "value", $$v)
                                    },
                                    expression: "searchProduct.value"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c(
                              "v-data-table",
                              {
                                staticClass: "tbl-type01",
                                attrs: {
                                  headers: _vm.productHeaders,
                                  items: _vm.productList,
                                  "item-key": "articleId",
                                  "items-per-page": 5,
                                  "hide-default-footer": true,
                                  "fixed-header": "",
                                  height: "340"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c(
                                            "tr",
                                            {
                                              class:
                                                index === _vm.productIndex
                                                  ? "selected products"
                                                  : "products",
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectProduct(
                                                    item,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  class:
                                                    index === _vm.productIndex
                                                      ? "select_text"
                                                      : "",
                                                  style: {
                                                    "line-break": "anywhere"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.articleName)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  class:
                                                    index === _vm.productIndex
                                                      ? "select_text"
                                                      : ""
                                                },
                                                [_vm._v(_vm._s(item.articleId))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  489357410
                                )
                              },
                              [
                                _c("template", { slot: "no-data" }, [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.$t("No data available")))
                                  ])
                                ])
                              ],
                              2
                            ),
                            _c("div", { staticClass: "mt-8" }, [
                              _c(
                                "div",
                                { staticClass: "pagination2" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "pagination-nav pagination-nav-prev",
                                      class:
                                        _vm.articlePage < 10
                                          ? "pagination-nav-disabled"
                                          : null,
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.goTenPageBackwards(
                                            _vm.articlePage,
                                            _vm.paging
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets//img/ico-paging-prev.png"),
                                          alt: "이전 10페이지 이동"
                                        }
                                      })
                                    ]
                                  ),
                                  _c("v-pagination", {
                                    staticStyle: { display: "block" },
                                    attrs: {
                                      length: _vm.totalPages * 1 || 1,
                                      "total-visible": 10,
                                      color: "#2f3b4c"
                                    },
                                    on: { input: _vm.paging },
                                    model: {
                                      value: _vm.articlePage,
                                      callback: function($$v) {
                                        _vm.articlePage = $$v
                                      },
                                      expression: "articlePage"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "text-center", attrs: { cols: "2" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-column-full",
                                on: { click: _vm.addArticle }
                              },
                              [
                                _c("v-icon", [_vm._v("mdi-play")]),
                                _vm._v("ADD")
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tbl-type01",
                              attrs: {
                                "single-select": "",
                                headers: _vm.productHeaders2,
                                "items-per-page": Number(10),
                                items: _vm.addProductList,
                                "item-key": "index",
                                "hide-default-footer": true,
                                "fixed-header": "",
                                height: "340"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      var index = ref.index
                                      return [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              style: {
                                                "line-break": "anywhere"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.articleName) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              " " + _vm._s(item.articleId) + " "
                                            )
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    "x-small": "",
                                                    color: "#000"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteArticle(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#e27070"
                                                      }
                                                    },
                                                    [_vm._v("mdi-close-circle")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3691603893
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", icon: "" },
                        on: { click: _vm.productConfirm }
                      },
                      [_vm._v(_vm._s(_vm.$t("Confirm")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", icon: "" },
                        on: { click: _vm.closeAssign }
                      },
                      [_vm._v(_vm._s(_vm.$t("Close")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.step === 2
          ? _c(
              "div",
              [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _c("v-text-field", {
                              staticClass: "form-input in-btn slot-visible",
                              attrs: {
                                label: _vm.$t("Template List"),
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                clearable: ""
                              },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchAnchors("Template")
                                },
                                "click:clear": _vm.clearEvent
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { text: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.searchAnchors(
                                                  "Template"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/btn-search.jpg"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                868498429
                              ),
                              model: {
                                value: _vm.searchTemplate.value,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchTemplate, "value", $$v)
                                },
                                expression: "searchTemplate.value"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _c("v-divider"),
                            _c("v-data-table", {
                              staticClass: "gray-data-table",
                              attrs: {
                                headers: _vm.templateHeaders,
                                items: _vm.templateList,
                                "item-key": "idx_template",
                                "hide-default-header": "",
                                "hide-default-footer": "",
                                height: "259"
                              },
                              on: { "click:row": _vm.selectedTemplate },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      var index = ref.index
                                      return [
                                        _c(
                                          "tr",
                                          {
                                            class:
                                              _vm.selectTemplateIndex === index
                                                ? "selected"
                                                : "",
                                            on: {
                                              click: function($event) {
                                                return _vm.selectedTemplate(
                                                  item,
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                class:
                                                  index ===
                                                  _vm.selectTemplateIndex
                                                    ? "select_text"
                                                    : "",
                                                attrs: {
                                                  colspan: !item.isFixed ? 2 : 0
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    style: {
                                                      "max-width": item.isFixed
                                                        ? "140px"
                                                        : "200px",
                                                      "text-overflow":
                                                        "ellipsis",
                                                      overflow: "hidden",
                                                      "white-space": "nowrap"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.template_name)
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            item.isFixed
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      width: "20%"
                                                    }
                                                  },
                                                  [
                                                    _vm.selectFixTemplate ===
                                                    item.idx_template
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                index ===
                                                                _vm.selectTemplateIndex
                                                                  ? "white"
                                                                  : "green"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.fixPreview(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-pin-outline "
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                index ===
                                                                _vm.selectTemplateIndex
                                                                  ? "white"
                                                                  : ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.fixPreview(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-pin-off-outline "
                                                            )
                                                          ]
                                                        )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3166710312
                              )
                            }),
                            _vm.selectTemplate.isFixed
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn width-full mt-2",
                                    attrs: { text: "", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.applyFixTemplate()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Apply Fixed Template"))
                                    )
                                  ]
                                )
                              : _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn width-full mt-2",
                                    attrs: { text: "", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.applyTemplate()
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Apply Template")))]
                                )
                          ],
                          1
                        ),
                        _c("v-divider", {
                          staticStyle: {
                            "border-width": "0px thin 0px 1px",
                            margin: "0 4%"
                          },
                          attrs: { vertical: "" }
                        }),
                        _c("v-col", { attrs: { cols: "7" } }, [
                          _c("h6", { staticClass: "caption text-left pb-2" }, [
                            _vm._v(_vm._s(_vm.$t("Selected Template Preview")))
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "selected-box mb-3",
                              staticStyle: { overflow: "hidden" }
                            },
                            [
                              _c(
                                "perfect-scrollbar",
                                {
                                  staticStyle: {
                                    width: "450px",
                                    height: "200px"
                                  }
                                },
                                [
                                  _c("canvas", {
                                    attrs: {
                                      id: "canvas-assign",
                                      width: _vm.canvas.width,
                                      height: _vm.canvas.height
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex time-slider mt-1" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex",
                                      staticStyle: {
                                        position: "relative",
                                        padding: "0px"
                                      },
                                      attrs: { tag: "ul" }
                                    },
                                    _vm._l(_vm.addProductList, function(item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.index,
                                          class:
                                            item.index === _vm.templateIndex
                                              ? "timecell-event selected"
                                              : "timecell-event",
                                          attrs: { id: "item" },
                                          on: {
                                            click: function($event) {
                                              return _vm.selectTemplateProduct(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.idx_template === "none"
                                                      ? ""
                                                      : "Apply"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _c("draggable")
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mt-5", attrs: { "no-gutters": "" } },
                      [
                        _c("v-pagination", {
                          staticStyle: { display: "block" },
                          attrs: {
                            length: Math.ceil(Number(_vm.template.total) / 6),
                            "total-visible": 10,
                            color: "#2f3b4c"
                          },
                          on: { input: _vm.templatePaging },
                          model: {
                            value: _vm.template.page,
                            callback: function($$v) {
                              _vm.$set(_vm.template, "page", $$v)
                            },
                            expression: "template.page"
                          }
                        }),
                        _c("v-spacer")
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", icon: "" },
                        on: { click: _vm.confirmAssign }
                      },
                      [_vm._v(_vm._s(_vm.$t("Update")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", icon: "" },
                        on: { click: _vm.closeAssign }
                      },
                      [_vm._v(_vm._s(_vm.$t("Back")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        [
          _c("v-progress-circular", {
            staticStyle: { width: "100%" },
            attrs: { indeterminate: "", size: 50, width: 7, color: "#ddd" }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300" },
          on: { "click:outside": _vm.fixPreviewClose },
          model: {
            value: _vm.fixPreviewDialog,
            callback: function($$v) {
              _vm.fixPreviewDialog = $$v
            },
            expression: "fixPreviewDialog"
          }
        },
        [
          _c("v-img", {
            attrs: { src: _vm.fixPreviewSrc },
            on: { click: _vm.fixPreviewClose }
          }),
          _c("div", {
            staticClass: "d-flex",
            staticStyle: { position: "relative" },
            style: {
              "background-image": _vm.fixPreviewSrc,
              "line-height": 1.5,
              "justify-content": "center"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }