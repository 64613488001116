<template>
  <v-dialog v-model="registDialog" width="512" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        text
        :disabled="btnDisabledEdit"
        class="btn ml-3"
        >{{ $t('Edit') }}
      </v-btn>
    </template>
    <v-card class="popup add_store_popup">
      <v-card-title><h3 class="page-title-bar" style="margin-bottom: 0px;"><i class="ico ico-lcd-list"></i>{{$t('Edit')}}</h3></v-card-title>
      <v-card-text class="px-9" style="padding:0!important">
        <v-row no-gutters>
          <v-col cols="4" class="mt-10">
              <p style="font-size: 12px;">
                {{$t('Model')}}
                <span class="redbold">*</span>
              </p>
            </v-col>
            <v-col cols="8" class="mt-10">
              <v-select
                v-model="form.model"
                :items="defaultModel"
                class="form-select pt-0"
                outlined
                dense
                hide-details>
              </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="mt-10">
            <p style="font-size: 12px;">
                      {{$t('UUID')}}
              <span class="redbold">*</span>
            </p>
          </v-col>
          <v-col cols="8" class="mt-10">
            <v-text-field
              v-model="form.uuid"
              disabled
              :placeholder="$t('Input UUID')"
              class="form-input pt-0"
              outlined
              dense
              hide-details
              clearable>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="mt-10">
            <p style="font-size: 12px;">
                      {{$t('MAC Address')}}
              <span class="redbold">*</span>
            </p>
          </v-col>
          <v-col cols="8" class="mt-10">
            <v-text-field
              v-model="form.mac"
              disabled
              :placeholder="$t('Input MAC')"
              class="form-input pt-0"
              outlined
              dense
              hide-details
              clearable>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="mt-10">
            <p style="font-size: 12px;">
                      {{$t('Nickname')}}
              <span class="redbold">*</span>
            </p>
          </v-col>
          <v-col cols="8" class="mt-10">
            <v-text-field
              v-model="form.nickname"
              :placeholder="$t('Input nickname')"
              class="form-input pt-0"
              outlined
              dense
              hide-details
              >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon class="btn" :disabled="form.model==='' || form.nickname===''" @click="EditLcdList">{{$t('Save')}}</v-btn>
        <v-btn text icon @click="closeEdit" class="btn">{{$t('Close')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus'
import dicamoApi from '@/lcd/plugins/dicamoApi'

export default {
  props: {
    user: Object,
    item: Array,
    btnDisabledEdit: Boolean
  },
  data () {
    return {
      registDialog: false,
      form: {
        model: '23',
        uuid: 'FFFFFFFFB1B48BD50033C5870033C587',
        nickname: '',
        mac: 'D0:25:44:F0:00:0C'
      }
      // code: ''
    }
  },
  methods: {
    EditLcdList () {
      const LcdList = new FormData()

      LcdList.append('company', this.$store.state.auth.user.company)
      LcdList.append('store', this.$store.state.dataStore.selectedStore.code)
      LcdList.append('device_type', this.form.model)
      LcdList.append('nickname', this.form.nickname)
      LcdList.append('mac', this.form.mac)
      LcdList.append('type', 'item')
      LcdList.append('timezone', this.$store.state.dataStore.timezone)
      LcdList.append('idx_lcd', this.item[0].idx_lcd)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.putLcdList.method,
          dicamoApi.requests.putLcdList.url,
          LcdList
        )
        .then(res => {
          if (res.data.result) {
            EventBus.$emit('messageAlert', res.data.message === '' ? this.$t('SUCCESS') : this.$t(res.data.message))
            this.$emit('fireResetLcd')
            this.closeEdit()
          } else {
          }
        })
        .catch(error => {
          console.debug(`Could not find any lcdList. error: ${error}`)
        })
    },
    closeEdit () {
      this.form = {
        model: 23,
        uuid: 'FFFFFFFFB1B48BD50033C5870033C587',
        nickname: '',
        mac: 'D0:25:44:F0:00:0C'
      }
      this.registDialog = false
    }
  },
  computed: {
    defaultModel () {
      return [
        {
          text: this.$t('Newton Touch 23"'),
          abbr: 'Newton Touch 23"',
          originalText: 'Newton Touch 23"',
          value: '23'
        },
        {
          text: this.$t('Newton Touch 29"'),
          abbr: 'Newton Touch 29"',
          originalText: 'Newton Touch 29"',
          value: '29'
        },
        {
          text: this.$t('FHD Box'),
          abbr: 'FHD',
          originalText: 'FHD',
          value: 'FHD'
        },
        {
          text: this.$t('Tablet'),
          abbr: 'Tablet',
          originalText: 'Tablet',
          value: 'Tablet'
        }
      ]
    }
  },
  watch: {
    registDialog: {
      handler (val) {
        if (!val) return
        let model = ''
        if (this.item[0].device_type === 'Newton Touch 23"') model = '23'
        else if (this.item[0].device_type === 'Newton Touch 29"') model = '29'
        else if (this.item[0].device_type === 'FHD Box') model = 'FHD'
        else if (this.item[0].device_type === 'Tablet') model = 'Tablet'
        this.form = {
          model: model,
          mac: this.item[0].mac,
          uuid: this.item[0].uuid,
          nickname: this.item[0].nickname
        }
      }
    }
  }
}
</script>

<style scoped>
.redbold {
  color: red;
  font-weight: bold;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
