<template>
  <v-dialog v-model="assignDialog" width="900" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn
        :disabled="btnDisabledAssign"
        class="btn btn-danger ml-3"
        v-bind="attrs"
        v-on="on"
        text
      >{{ $t('Product Assign') }}</v-btn> -->
      <v-btn
        v-if="btype === 'assign'"
        :disabled="btnDisabledAssign"
        class="btn btn-danger ml-3"
        v-bind="attrs"
        v-on="on"
        text
      >{{ $t('Product Assign') }}</v-btn>
      <v-btn
        v-if="btype === 'edit'"
        :disabled="btnDisabledAssign"
        class="btn"
        v-bind="attrs"
        v-on="on"
        text
      >{{ $t('Edit Assign Info') }}</v-btn>
    </template>
    <v-card class="popup add_store_popup">
        <div class="d-flex">
          <!-- <h3 class="page-title-bar"><i class="ico ico-lcd-list"></i>{{mode === 'assign' ? $t('Product Assign') : $t('Edit Assign Info')}}</h3> -->
          <v-card-title> <h3 class="page-title-bar"><i class="ico ico-lcd-list"></i>{{$t('Product Assign')}}</h3></v-card-title>
          <div class="ml-auto">
            <ul class="step-progressbar">
              <li :class="stepClass(1)"><span>{{$t('Select')}}<br>{{$t('Product')}}</span></li>
              <li :class="stepClass(2)"><span>{{$t('Select')}}<br>{{$t('Template')}}</span></li>
              <li :class="stepClass(3)"><span>{{$t('Assign')}}<br>{{$t('Complete')}}</span></li>
            </ul>
          </div>
        </div>
        <div v-if="step === 1">
          <v-card-text class="pa-0">
            <div class="mb-4" style="width: 70%;">
              <v-row>
                <v-col cols="4">
                  <v-select
                    class="form-input in-btn slot-visible"
                    :label="$t('Product Type')"
                    v-model="searchProduct.type"
                    :items="['Product Name', 'Product ID']"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="searchProduct.value"
                    @keyup.enter="searchAnchors('Product')"
                    :label="$t('Product List')"
                    class="form-input in-btn slot-visible"
                    outlined
                    dense
                    hide-details
                    clearable>
                    <template v-slot:append>
                      <v-btn @click="searchAnchors('Product')" text>
                        <img src="@/assets/img/btn-search.jpg" alt="" />
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              </div>
            <v-row no-gutters>
              <v-col cols="5">
                <v-data-table
                  :headers="productHeaders"
                  :items="productList"
                  item-key="articleId"
                  :items-per-page="5"
                  :hide-default-footer="true"
                  fixed-header
                  height="340"
                  class="tbl-type01"
                >
                <template v-slot:item="{ item, index }">
                  <tr @click="selectProduct(item, index)" :class="index === productIndex ? 'selected products' : 'products'">
                    <td :class="index === productIndex ? 'select_text' : ''" :style="{ 'line-break': 'anywhere' }">{{ item.articleName }}</td>
                    <td :class="index === productIndex ? 'select_text' : ''">{{ item.articleId }}</td>
                  </tr>
                </template>
                <template slot="no-data">
                  <p>{{ $t('No data available') }}</p>
                </template>
                </v-data-table>
                <div class="mt-8">
                  <div class="pagination2">
                    <!-- 페이지 앞 뒤 이동 -->
                    <button
                      @click="goTenPageBackwards(articlePage, paging)"
                      :class="articlePage < 10 ? 'pagination-nav-disabled' : null"
                      class="pagination-nav pagination-nav-prev"
                      type="button"
                    >
                      <img
                        src="@/assets//img/ico-paging-prev.png"
                        alt="이전 10페이지 이동"
                      />
                    </button>
                    <v-pagination
                      v-model="articlePage"
                      @input="paging"
                      :length="totalPages * 1 || 1"
                      :total-visible="10"
                      color="#2f3b4c"
                      style="display: block;"
                    ></v-pagination>
                  </div>
                </div>
              </v-col>
              <v-col cols="2" class="text-center">
                <v-btn class="btn-column-full" @click="addArticle"><v-icon>mdi-play</v-icon>ADD</v-btn>
              </v-col>
              <v-col cols="5">
                <v-data-table
                  single-select
                  :headers="productHeaders2"
                  :items-per-page="Number(10)"
                  :items="addProductList"
                  item-key="index"
                  :hide-default-footer="true"
                  fixed-header
                  height="340"
                  class="tbl-type01"
                >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td :style="{ 'line-break': 'anywhere' }"> {{ item.articleName }} </td>
                    <td> {{ item.articleId }} </td>
                    <td> <v-btn icon x-small color="#000" @click="deleteArticle(index)" ><v-icon color="#e27070">mdi-close-circle</v-icon></v-btn> </td>
                  </tr>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn text icon @click="productConfirm" class="btn">{{$t('Confirm')}}</v-btn>
            <v-btn text icon @click="closeAssign" class="btn">{{$t('Close')}}</v-btn>
          </v-card-actions>
        </div>

        <div v-if="step===2">
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col cols="4">
                <v-text-field
                    v-model="searchTemplate.value"
                    @keyup.enter="searchAnchors('Template')"
                    @click:clear="clearEvent"
                    :label="$t('Template List')"
                    class="form-input in-btn slot-visible"
                    outlined
                    dense
                    hide-details
                    clearable>
                    <template v-slot:append>
                      <v-btn @click="searchAnchors('Template')" text>
                        <img src="@/assets/img/btn-search.jpg" alt="" />
                      </v-btn>
                    </template>
                  </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-col cols="4">
                <v-divider></v-divider>
                <v-data-table
                  @click:row="selectedTemplate"
                  :headers="templateHeaders"
                  :items="templateList"
                  item-key="idx_template"
                  hide-default-header
                  hide-default-footer
                  height="259"
                  class="gray-data-table"
                >
                <template v-slot:item="{ item, index }">
                  <tr @click="selectedTemplate(item, index)" :class="selectTemplateIndex === index ? 'selected' : ''">
                    <td :class="index === selectTemplateIndex ? 'select_text' : ''" :colspan="!item.isFixed ? 2 : 0">
                      <div :style="{ 'max-width': item.isFixed ? '140px' : '200px', 'text-overflow': 'ellipsis', overflow: 'hidden', 'white-space': 'nowrap' }">{{ item.template_name }}</div>
                    </td>
                    <td style="width: 20%" v-if="item.isFixed">
                      <v-icon @click="fixPreview(item)" v-if="selectFixTemplate === item.idx_template" :color="index === selectTemplateIndex ? 'white' : 'green'"> mdi-pin-outline </v-icon>
                      <v-icon @click="fixPreview(item)" v-else :color="index === selectTemplateIndex ? 'white' : ''"> mdi-pin-off-outline </v-icon>
                    </td>
                  </tr>
                </template>
                </v-data-table>
                <v-btn text icon @click="applyFixTemplate()" class="btn width-full mt-2" v-if="selectTemplate.isFixed">{{$t('Apply Fixed Template')}}</v-btn>
                <v-btn text icon @click="applyTemplate()" class="btn width-full mt-2" v-else>{{$t('Apply Template')}}</v-btn>
              </v-col>
              <v-divider vertical style="border-width:0px thin 0px 1px;margin:0 4%"></v-divider>
              <v-col cols="7">
                <h6 class="caption text-left pb-2">{{$t('Selected Template Preview')}}</h6>

                <!-- <div v-if="templatePreview" class="selected-template-box" :style="{ 'background-size': 'contain', 'background-image': 'url(data:image/png;base64,' + templatePreview + ')', 'background-repeat': 'no-repeat' }"></div> -->
                  <div class="selected-box mb-3" style="overflow: hidden;">
                    <perfect-scrollbar style="width: 450px; height: 200px;">
                      <canvas id="canvas-assign" :width="canvas.width" :height="canvas.height"></canvas>
                    <!-- <div v-if="articleData.length > 0" style="width: 200px; height: 200px;" :style="{ 'background-image': 'url(' + getThumbnail(articleData[templateIndex].template_img) + ')', 'background-size': 'contain' }" /> -->
                    <!-- <div v-if="articleData.length > 0" :style="{ 'background-image': 'url(' + templateURL + articleData[templateIndex].template_img + ')', 'background-size': 'contain' }" /> -->
                      <!-- <div v-if="mode === 'assign' && addProductList.length > 0" :style="{ width: addProductList[templateIndex].templateData.width + 'px', height: addProductList[templateIndex].templateData.height + 'px' }">
                        <div v-if="!(selectTemplateIndex === '')" style="position: relative" class="d-flex" :style="{ 'background-image': getTemplatePreview(), 'justify-content': 'center', width: getWidth(), height: getHeight() }">
                          <div v-for="(item, index) in templatePreviewArticle" :key="index">
                              <span :style="item.style">{{ item.column !== 'BARCODE' ? item.text : '' }}</span>
                              <barcode class="barcode" :options="{ displayValue: false }" v-if="item.barcode !== ''" v-bind:value="item.barcode" tag="img" />
                          </div>
                        </div>
                      </div>
                      <div v-else-if="mode === 'edit' && addProductList.length > 0" :style="{ width: getWidth(), height: getHeight() }">
                        <div style="position: relative" class="d-flex" :style="{ 'background-image': getTemplatePreview(), 'line-height': 1, 'white-space': 'nowrap', 'justify-content': 'center', width: getWidth(), height: getHeight() }">
                          <div :style="item.style" v-for="(item, index) in templatePreviewArticle" :key="index">
                              {{ item.column !== 'BARCODE' ? item.text : '' }}
                              <barcode class="barcode" :options="{ displayValue: false }" v-if="item.barcode !== ''" v-bind:value="item.barcode" tag="img" />
                          </div>
                        </div>
                      </div> -->
                    </perfect-scrollbar>
                  </div>
                  <!-- <v-row>
                    <v-col cols="3">
                      <h6 class="caption text-left mt-6 pb-2">Align Setting</h6>
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="3">
                      <h6 class="caption text-right mt-6 pb-2" > Fixed Template </h6>
                    </v-col>
                    <v-col cols="4">
                      <v-btn rounded small block v-if="fixTemplate" @click="fixPreview(fixTemplate)" class="caption mt-6 text-center" style="background: #467dc6; color: white; overflow: hidden; overflow-wrap: normal; text-overflow: ellipsis;"> {{ fixTemplate ? fixTemplate.template_name : '' }} </v-btn>
                    </v-col>
                    <v-col cols="1">
                      <v-btn v-if="fixTemplate" class="caption mt-6 pb-2" icon small @click="deleteFixTemplate"> <v-icon color="red"> mdi-close </v-icon> </v-btn>
                    </v-col>

                  </v-row> -->
                <!-- <div class="d-flex align-center align-action">
                  <div>
                    <v-btn icon small><v-icon small>mdi-align-vertical-top</v-icon></v-btn>
                    <v-btn icon small><v-icon small>mdi-align-vertical-center</v-icon></v-btn>
                    <v-btn icon small><v-icon small>mdi-align-vertical-bottom</v-icon></v-btn>
                  </div>
                  <div class="ml-6">
                    <v-btn icon small><v-icon small>mdi-align-horizontal-left</v-icon></v-btn>
                    <v-btn icon small><v-icon small>mdi-align-horizontal-center</v-icon></v-btn>
                    <v-btn icon small><v-icon small>mdi-align-horizontal-right</v-icon></v-btn>
                  </div>
                </div> -->
                <div class="d-flex time-slider mt-1">
                  <div class="d-flex align-center">
                    <!-- <div ref="draggableContainer" id="draggable-container" v-for="(item) in addProductList" :key="item.index" @mousedown="dragMouseDown" >
                      <div @click="selectTemplateProduct(item)" id="item" :class="item.index === templateIndex ? 'timecell-event selected' : 'timecell-event'" :style="getTemplateStyle(item)">
                        {{ item.idx_template === 'none' ? '' : 'Apply' }}
                      </div>
                    </div> -->
                    <!-- <div> -->
                        <!-- v-for="(item) in addProductList" :key="item.index" -->
                      <div
                        class="d-flex"
                        style="position: relative; padding: 0px;"
                        tag="ul">
                        <div v-for="(item) in addProductList" @click="selectTemplateProduct(item)" :key="item.index" id="item" :class="item.index === templateIndex ? 'timecell-event selected' : 'timecell-event'" >
                          <span class="text-center"> {{ item.idx_template === 'none' ? '' : 'Apply' }} </span>
                        </div>
                      </div>
                      <draggable />
                      <!-- <SlickList axis="x" lockAxis="x" v-model="addProductList" tag="ul" class="d-flex" lockToContainerEdges :pressDelay="200" @click.native="selectTemplateProduct($event)" @sort-start="null">
                        <SlickItem v-for="(item, index) in addProductList" :index="index" :key="index" tag="div" :class="item.index === templateIndex ? 'timecell-event selected' : 'timecell-event'" style="margin-right: 2px"
                          >
                          {{ item.idx_template === 'none' ? '' : 'Apply' }}
                        </SlickItem>
                      </SlickList> -->
                    <!-- </div> -->
                    <!-- <div class="timecell-event"></div>
                    <div class="padding-event">10</div>
                    <div class="timecell-event"></div>
                    <div class="padding-event">10</div>
                    <div class="timecell-event"></div>
                    <div class="padding-event">10</div>
                    <div class="timecell-event"></div>
                    <div class="padding-event">10</div>
                    <div class="timecell-event"></div>
                  </div> -->

                  <!-- <div class="graduation-title middle">0</div>
                  <div class="graduation-title" style="left:10%">0</div>
                  <div class="graduation-title middle" style="left:20%">0</div>
                  <div class="graduation-title" style="left:30%">0</div>
                  <div class="graduation-title middle" style="left:40%">0</div>
                  <div class="graduation-title" style="left:50%">0</div>
                  <div class="graduation-title middle" style="left:60%">0</div>
                  <div class="graduation-title" style="left:70%">0</div>
                  <div class="graduation-title middle" style="left:80%">0</div>
                  <div class="graduation-title" style="left:90%">0</div>
                  <div class="graduation-title middle" style="left:100%">0</div> -->

                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-5" no-gutters>
              <v-pagination
                v-model="template.page"
                @input="templatePaging"
                :length="Math.ceil(Number(template.total) / 6)"
                :total-visible="10"
                color="#2f3b4c"
                style="display: block;"
              ></v-pagination>
              <v-spacer />
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn text icon class="btn" @click="confirmAssign">{{$t('Update')}}</v-btn>
            <v-btn text icon @click="closeAssign" class="btn">{{$t('Back')}}</v-btn>
          </v-card-actions>
        </div>
        <!-- <div v-if="step===3">
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col cols="12">
                <div :style="{ width: '800px', height: assignHeight + 'px', outline: 'solid 2px black', position: 'relative' }">
                  <div v-for="(item) in addProductList" draggable :key="item.index" class="timecell-event" :style="getTemplateStyle(item)">
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn text icon class="btn" @click="confirmAssign">{{$t('Assign')}}</v-btn>
            <v-btn text icon @click="closeAssign" class="btn">{{$t('Back')}}</v-btn>
          </v-card-actions>
        </div> -->
    </v-card>
    <v-dialog v-model="isLoading" persistent>
      <v-progress-circular
        style="width:100%;"
        indeterminate
        :size="50"
        :width="7"
        color="#ddd"
      ></v-progress-circular>
    </v-dialog>
    <v-dialog v-model="fixPreviewDialog" width="300" @click:outside="fixPreviewClose">
      <v-img :src="fixPreviewSrc" @click="fixPreviewClose"> </v-img>
      <div style="position: relative" class="d-flex" :style="{ 'background-image': fixPreviewSrc, 'line-height': 1.5, 'justify-content': 'center'}" />
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
import dicamoApi from '@/lcd/plugins/dicamoApi'
import draggable from 'vuedraggable'
// import VueBarcode from '@xkeshi/vue-barcode'
// import { SlickList, SlickItem } from 'vue-slicksort'
// import axios from 'axios'
// import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    draggable
    // SlickList,
    // SlickItem,
    // barcode: VueBarcode
  },
  props: {
    btnDisabledAssign: Boolean,
    user: Object,
    lcdData: Array,
    mode: String,
    btype: String
  },
  watch: {
    assignDialog: {
      handler (val) {
        if (val) {
          if (this.lcdData.length > 0) {
            const index = this.lcdData[0].idx_lcd
            this.getLcdItem(index)
          }
          this.getProductList(1)
          this.getTemplateList()
        }
      }
    },
    isLoading (val) {
      if (val) {
        setTimeout(() => { this.isLoading = false }, 2000)
      }
    }
  },
  beforeDestroy () {
    clearTimeout()
  },
  data () {
    return {
      selectedArticle: [],
      fixPreviewDialog: '',
      fixPreviewSrc: '',
      isLoading: false,
      templateIndex: 0,
      templatePreview: '',
      articlePage: 1,
      page: 1,
      step: 1,
      currentSize: 0,
      totalSize: 1920,
      totalPages: 0,
      searchTemplate: {
        value: ''
      },
      searchProduct: {
        type: 'Product Name',
        value: ''
      },
      template: {
        page: 1,
        total: 0,
        searchWord: ''
      },
      canvas: {
        width: 0,
        height: 0
      },
      fixTemplate: null,
      productIndex: '',
      selectTemplateIndex: '',
      productList: [],
      addProductList: [],
      selectArticle: [],
      selectTemplate: [],
      templateList: [],
      assignDialog: false,
      productHeaders: [
        { text: this.$t('PRODUCT NAME'), align: 'start', sortable: false, value: 'articleName', width: '70%' },
        { text: this.$t('PRODUCT ID'), align: 'start', sortable: false, value: 'articleId', width: '30%' }
      ],
      productHeaders2: [
        { text: this.$t('PRODUCT NAME'), align: 'start', sortable: false, value: 'articleName', width: '70%' },
        { text: this.$t('PRODUCT ID'), align: 'start', sortable: false, value: 'articleId', width: '30%' },
        { text: '', align: 'start', sortable: false, value: '' }
      ],
      templateHeaders: [
        { text: '', align: 'start', sortable: false, value: 'template_name', width: '90%' },
        { text: '', align: 'start', sortable: false, value: 'fixed', width: '10%' }
      ],
      articleData: [],
      templateURL: dicamoApi.template
    }
  },
  mounted () {
    this.getProductList(1)
    this.getTemplateList()
    // if (this.mode === 'edit') {
    //   this.getTemplateData()
    // }
  },
  methods: {
    applyFixTemplate () {
      this.fixTemplate = this.selectTemplate
    },
    deleteFixTemplate () {
      this.fixTemplate = null
    },
    fixPreview (item) {
      console.log(item)
      this.fixPreviewDialog = true

      // let templatePreview = ''
      this.$nextTick(() => {
        const templatePreview = `${this.templateURL}${item.idx_template}.png`
        this.fixPreviewSrc = templatePreview + '?t=' + new Date().getTime()
      })
    },
    fixPreviewClose () {
      this.fixPreviewDialog = false
      this.fixPreviewSrc = ''
    },
    getTemplateStyle (item) {
      const deviceHeight = this.lcdData[0].device_type === '23' || this.lcdData[0].device_type === 'Newton Touch 23"' ? 158 : 540
      const widthRatio = 800 / 1920
      const heightRatio = this.assignHeight / deviceHeight
      // console.log(this.assignHeight)
      // console.log(deviceHeight)
      return {
        position: 'absolute',
        width: item.templateData === 'none' ? '120px' : Number(item.templateData.width) * widthRatio + 'px',
        left: Number(item.xposition) * widthRatio + 'px',
        height: item.templateData === 'none' ? this.assignHeight + 'px' : Number(item.templateData.height) * heightRatio + 'px',
        top: Number(item.yposition) * heightRatio + 'px',
        'max-width': '800px',
        'max-height': Number(this.assignHeight) + 10 + 'px'
      }
    },
    getWidth () {
      // if (this.addProductList[this.templateIndex].idx_template === 'none' && this.selectTemplate) return '0px'
      if (this.addProductList[this.templateIndex].idx_template === 'none' && this.selectTemplateIndex !== '') return this.selectTemplate.template_data.width + 'px'
      else return this.addProductList[this.templateIndex].templateData.width + 'px'
    },
    getHeight () {
      // if (this.addProductList[this.templateIndex].idx_template === 'none' && this.selecteTemplate) return '0px'
      if (this.addProductList[this.templateIndex].idx_template === 'none' && this.selectTemplateIndex !== '') return this.selectTemplate.template_data.height + 'px'
      else return this.addProductList[this.templateIndex].templateData.height + 'px'
    },
    getTemplatePreview () {
      let templatePreview = ''
      // if (this.addProductList[this.templateIndex].idx_template === 'none') {
      //   templatePreview = 'url(data:image/png;base64,' + this.selectTemplate.template_img + ')'
      // } else if (this.mode === 'assign') {
      //   templatePreview = 'url(data:image/png;base64,' + this.addProductList[this.templateIndex].templatePreview + ')'
      // } else if (this.mode === 'edit' && this.addProductList[this.templateIndex].change) {
      //   templatePreview = 'url(data:image/png;base64,' + this.addProductList[this.templateIndex].templatePreview + ')'
      // } else if (this.mode === 'edit' && !this.addProductList[this.templateIndex].change) {
      //   templatePreview = 'url(' + this.templateURL + this.addProductList[this.templateIndex].templatePreview + ')'
      // }
      if (this.addProductList[this.templateIndex].idx_template === 'none') {
        if (this.selectTemplateIndex === '') templatePreview = ''
        else templatePreview = `url(${this.templateURL}${this.selectTemplate.idx_template}.png?t=${Math.round(new Date().getTime() / 1000)})`
      } else {
        templatePreview = 'url(' + this.templateURL + this.addProductList[this.templateIndex].templatePreview + '?t=' + Math.round(new Date().getTime() / 1000) + ')'
      }
      return templatePreview
    },
    getLcdItem (index) {
      this.isLoading = true
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('idx_lcd', index)
      data.append('type', 'stripe')
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getLcdTemplateAndProduct.method,
          dicamoApi.requests.getLcdTemplateAndProduct.url,
          data
        )
        .then(res => {
          this.articleData = res.data.data
          this.setLcdData(res.data.data)
          // this.isLoading = false
          // console.log(this.articleData)
        })
        .catch(error => {
          this.lcdlist = []
          console.debug(`Could not find any template. error: ${error}`)
        })
    },
    setLcdData (items) {
      const list = []
      // for (let index = 0; index < items.length; index++) {
      //   list.push({
      //     articleId: items[index].product_id,
      //     articleName: items[index].article_info.article.articleName,
      //     idx_template: items[index].idx_template,
      //     index: index,
      //     templateData: items[index].template_data,
      //     templatePreview: 'none'
      //   })
      // }
      if (items.length > 0) {
        let index = 0
        items.forEach(item => {
          if (item.product_id === 'DICAMO_FIX_TEMPLATE') {

          } else {
            list.push({
              articleId: item.product_id,
              articleName: item.article_info.article.articleName,
              idx_template: item.idx_template,
              index: index,
              templateData: JSON.parse(item.template_data),
              templatePreview: item.template_img,
              xposition: item.xposition,
              yposition: item.yposition,
              change: false
            })
            index++
          }
        })
      }
      // items.forEach(item => {
      //   list.push({
      //     articleId = item.product_id,
      //     articleName = item.title,

      //   })
      // })
      this.isLoading = false
      this.addProductList = list
    },

    getArticle (articleId) {
      const article = this.selectedArticle.data
      if (article) {
        for (const [key, value] of Object.entries(article)) {
          if (key === articleId) {
            return value
          }
        }
      }
      // console.log(this.selectedArticle.data.get(articleId))
      // return
    },
    /**
     *  @TODO - 09월 배포 시 적용 필요
     *  아티클 데이터 찾는 method
     *  다음 Version 배포 시 테스트 후 해당 함수로 변경되어야함
     *  */
    getArticleText (id, index) {
      let text = ''
      const article = this.selectedArticle
      text = !article[index] ? '' : article[index].data[id]
      return text
    },
    selectTemplateProduct (event) {
      // console.log(event)
      // this.getProductDetail(this.selectArticle.articleId)
      // console.log(event)

      // this.addProductList.forEach((item, index) => {
      //   console.log(item.index + ' ' + event.target.sortableInfo.index)
      //   if (index === event.target.sortableInfo.index) {
      //     console.log('done')
      //     this.templateIndex = item.index
      //     this.selectArticle = item
      //     console.log(item)
      //     this.templatePreview = item.templatePreview
      //   }
      // })

      this.templateIndex = event.index
      this.selectArticle = event

      // if (event.templatePreview) {
      //   this.templatePreview = event.templatePreview
      // } else {
      //   this.templatePreview = ''
      // }
      this.changePreview()

      // console.log(this.templateIndex)
    },
    selectProduct (item, index) {
      // console.log(this.selectArticle)
      this.productIndex = index
      this.selectArticle = item
      // console.log(item)
      // console.log(index)
    },
    selectedTemplate (item, index) {
      // console.log(item)
      // console.log(index)
      this.selectTemplate = item
      this.selectTemplateIndex = index
      this.selectArticle = this.addProductList.length > 0 ? this.addProductList[this.templateIndex] : null
      // this.getProductDetail(this.selectArticle.articleId)
      // this.addProductList[this.templateIndex].templatePreview = this.selectTemplate.template_img
      // console.log(item)
      // console.log(index)
      this.changePreview()
    },
    changePreview () {
      this.isLoading = true
      const canvas = document.getElementById('canvas-assign')
      const ctx = canvas.getContext('2d')
      let text = ''

      const img = new Image()
      if (this.selectArticle.templateData === 'none') {
        if (this.selectTemplate.length === 0) return
        this.canvas.width = this.selectTemplate.template_data.width
        this.canvas.height = this.selectTemplate.template_data.height
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      } else {
        this.canvas.width = this.selectArticle.templateData.width
        this.canvas.height = this.selectArticle.templateData.height
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      }
      img.addEventListener('load', () => {
        // ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height)
        let articleList = []
        if (this.selectArticle.templateData === 'none') articleList = this.selectTemplate.template_data.bind
        else articleList = this.selectArticle.templateData.bind

        articleList.forEach((item, index) => {
          if (item.column !== 'barcode') {
            text = this.getArticleText(item.column, this.templateIndex)
            if (!text) return
            // ctx = canvas.getContext('2d')
            let maxLine = item.line === 0 ? 999 : item.line
            if (!maxLine) maxLine = 999
            // const align = item.align === 'left' ? 'start' : item.align === 'right' ? 'end' : 'center'
            ctx.font = `${item.fontSize}px ${item.fontFamily}`
            ctx.strokeStyle = item.stroke
            if (item.y < item.fontSize) {
              ctx.textBaseline = 'bottom'
            } else {
              ctx.textBaseline = 'top'
            }
            // ctx.lineWidth = item.strokeWidth
            ctx.lineWidth = item.strokeWidth
            ctx.width = item.width
            ctx.height = item.height
            ctx.textAlign = item.align
            ctx.fillStyle = item.fill
            let offset = 0
            if (item.align === 'left') offset = 0
            else if (item.align === 'center') offset = item.width / 2
            else if (item.align === 'right') offset = item.width
            if (!item.wrap) item.wrap = 'word'

            // console.log(`align : ${item.align} offset: ${offset} x: ${item.x}`)

            if (!item.fit) {
              let line = ''
              let lineHeight = 0
              let count = 0
              let testLine = ''
              let metrics = ''
              let testWidth = 0
              const maxWidth = item.width
              if (item.wrap === 'word') {
                const words = String(text).split(' ')

                for (let n = 0; n < words.length; n++) {
                  testLine = line + words[n]
                  metrics = ctx.measureText(testLine)
                  testWidth = metrics.width
                  if (testWidth > maxWidth && n > 0 && item.height > lineHeight) {
                    if (maxLine > count) {
                      if (item.strokeWidth > 0) ctx.strokeText(line, item.x + offset, item.y + lineHeight)
                      ctx.fillText(line, item.x + offset, item.y + lineHeight)
                    }
                    line = words[n] + ' '
                    lineHeight += item.fontSize
                    count++
                  } else {
                    line = testLine + ' '
                  }
                }
                if (line.length > 0 && maxLine > count) {
                  if (item.strokeWidth > 0) ctx.strokeText(line, item.x + offset, item.y + lineHeight, item.width)
                  ctx.fillText(line, item.x + offset, item.y + lineHeight, item.width)
                }
              } else {
                // const words = String(text)
                for (let i = 0; i < text.length; i++) {
                  testLine = line + text[i]
                  metrics = ctx.measureText(testLine)
                  testWidth = metrics.width
                  if (testWidth > maxWidth && i > 0 && item.height > lineHeight) {
                    if (maxLine > count) {
                      if (item.strokeWidth > 0) ctx.strokeText(testLine, item.x + offset, item.y + lineHeight)
                      ctx.fillText(testLine, item.x + offset, item.y + lineHeight)
                    }
                    line = text[i] + ''
                    lineHeight += item.fontSize
                    count++
                  } else {
                    line = testLine + ''
                  }
                }
                if (line.length > 0 && maxLine > count) {
                  if (item.strokeWidth > 0) ctx.strokeText(testLine, item.x + offset, item.y + lineHeight)
                  ctx.fillText(testLine, item.x + offset, item.y + lineHeight)
                }
              }
            } else {
              if (item.strokeWidth > 0) ctx.strokeText(text, item.x, item.y + item.fontSize, item.width)
              ctx.fillText(text, item.x, item.y + item.fontSize, item.width)
            }
          }
        })
        this.isLoading = false
      })

      let templatePreview = ''

      // if (this.addProductList[this.templateIndex].idx_template === 'none') {
      //   if (this.selectTemplateIndex === '') templatePreview = ''
      //   else templatePreview = `url(${this.templateURL}${this.selectTemplate.idx_template}.png?t=${Math.round(new Date().getTime() / 1000)})`
      // } else {
      //   templatePreview = 'url(' + this.templateURL + this.addProductList[this.templateIndex].templatePreview + '?t=' + Math.round(new Date().getTime() / 1000) + ')'
      // }
      if (this.addProductList[this.templateIndex].idx_template === 'none') {
        if (this.addProductList[this.templateIndex].templatePreview === 'none') templatePreview = this.templateURL + this.selectTemplate.idx_template + '.png?t=' + Math.round(new Date().getTime() / 1000)
        else if (this.addProductList[this.templateIndex].templatePreview) templatePreview = this.templateURL + this.addProductList[this.templateIndex].templatePreview + '?t=' + Math.round(new Date().getTime() / 1000)
        else if (this.selectTemplateIndex === '') templatePreview = ''
        else templatePreview = `${this.templateURL}${this.selectTemplate.idx_template}.png?t=${Math.round(new Date().getTime() / 1000)}`
      } else {
        if (this.addProductList[this.templateIndex].templatePreview === 'none') templatePreview = 'data:image/png;base64,' + this.selectTemplate.template_img
        templatePreview = this.templateURL + this.addProductList[this.templateIndex].templatePreview + '?t=' + Math.round(new Date().getTime() / 1000)
        // console.log(templatePreview)
      }

      img.src = templatePreview
    },
    getProductDetail (articleIds) {
      if (commons.isNull(articleIds)) return
      this.selectedArticle = []
      articleIds.forEach((item, index) => {
        const params = {
          store: this.$store.state.dataStore.selectedStore.code,
          article: item
        }
        const config = { params }
        params.company = this.$store.state.auth.user.company
        this.$utils
          .callAxios(
            codes.requests.getAnArticle.method,
            codes.requests.getAnArticle.url,
            config
          )
          .then(res => {
            const data = res.data
            data.index = index
            this.selectedArticle.push(data)
            this.selectedArticle.sort((a, b) => {
              if (a.index > b.index) {
                return 1
              }
              if (a.index < b.index) {
                return -1
              }
              return 0
            })
          })
          .catch(error => {
            console.debug(`Failed to get data of an article. ${error}`)
          })
      })
    },
    applyTemplate () {
      // this.getProductDetail(this.selectArticle.articleId)
      this.isLoading = true
      let size = 0
      this.addProductList.forEach(item => {
        if (item.templateData !== 'none') {
          size += Number(item.templateData.width)
        }
      })
      if (this.addProductList[this.templateIndex].templateData !== 'none') size -= Number(this.addProductList[this.templateIndex].templateData.width)
      size += Number(this.selectTemplate.template_data.width)
      this.currentSize = size
      // console.log(size)
      // if (size > 1920) {
      //   EventBus.$emit('messageAlert', this.$t('Template total size cannot exceed 1920px'))
      //   return
      // }
      if (this.templateIndex === '') {
        EventBus.$emit('messageAlert', this.$t('Area is not selected'))
        return
      }
      if (this.selectTemplateIndex === '') {
        EventBus.$emit('messageAlert', this.$t('Template is not selected'))
        return
      }
      // this.addProductList[this.templateIndex].idx_template = this.selectTemplate.idx_template
      // if (this.mode === 'assign') {
      //   this.addProductList.forEach(item => {
      //     if (item.index === this.templateIndex) {
      //       item.idx_template = this.selectTemplate.idx_template
      //       item.templatePreview = this.selectTemplate.template_img
      //       item.templateData = this.templateData
      //     // this.templatePreview = this.selectTemplate.template_img
      //     }
      //   // this.templatePreview = item.templatePreview
      //   })
      // } else if (this.mode === 'edit') {
      //   this.addProductList.forEach(item => {
      //     if (item.index === this.templateIndex) {
      //       item.idx_template = this.selectTemplate.idx_template
      //       item.templatePreview = this.selectTemplate.template_img
      //       item.templateData = this.templateData
      //       item.change = true
      //     // this.templatePreview = this.selectTemplate.template_img
      //     }
      //   // this.templatePreview = item.templatePreview
      //   })
      // }

      this.addProductList.forEach(item => {
        if (item.index === this.templateIndex) {
          item.idx_template = this.selectTemplate.idx_template
          // item.templatePreview = this.selectTemplate.template_img
          item.templatePreview = `${this.selectTemplate.idx_template}.png`
          item.templateData = this.templateData
        }
      })

      EventBus.$emit('messageAlert', this.$t('Apply Template'))
      this.changePreview()

      // this.setTemplate = this.selectTemplate
    },
    addArticle () {
      if (this.addProductList.length > 9) {
        EventBus.$emit('messageAlert', this.$t('Product maximum 10'))
        return
      }
      if (commons.notEmpty(this.selectArticle)) {
        this.addProductList.push({
          index: '',
          articleId: this.selectArticle.articleId,
          articleName: this.selectArticle.articleName,
          idx_template: 'none',
          templatePreview: 'none',
          templateData: 'none',
          xposition: 0,
          yposition: 0
        })
      } else {
        EventBus.$emit('messageAlert', this.$t('No selected product'))
      }
    },
    deleteArticle (index) {
      this.addProductList.splice(index, 1)
      this.addProductList.forEach((item, index) => {
        item.index = index
      })
    },
    // deleteArticle () {
    //   this.addSelectArticle.
    // },
    initialize () {
      this.fixTemplate = null
      this.searchProduct.value = ''
      this.searchTemplate.value = ''
      this.addProductList = []
      this.selectTemplate = []
      this.selectTemplateIndex = ''
      this.productList = []
      this.templateIndex = 0
      this.templatePreview = ''
      this.productIndex = ''
      this.step = 1
      this.articlePage = 1
      this.selectArticle = []
      this.template.page = 1
    },

    paging (articlePage = this.articlePage) {
      this.articlePage = Number(articlePage)
      this.goToPageInput = articlePage
      this.getProductList(this.articlePage)
      this.productIndex = ''
      this.selectArticle = []
    },
    templatePaging () {
      this.getTemplateList()
    },

    buildParmas () {
      const params = { store: this.store.code }
      // if (commons.isValidStr(this.searchProduct.value)) {
      //   params.name = this.searchProduct.value
      // }
      return params
    },
    getProductList (page) {
      this.isLoading = true
      const params = this.buildParmas()
      if (commons.isNull(params.store)) return
      if (page !== null) params.page = page - 1
      const config = { params: params }

      // const config = {
      //   params: {
      //     store: this.$store.state.dataStore.selectedStore.code,
      //     company: this.$store.state.auth.user.company,
      //     size: 5
      //   }
      // }
      if (this.searchProduct.type === 'Product Name') config.params.name = this.searchProduct.value
      else if (this.searchProduct.type === 'Product ID') config.params.id = this.searchProduct.value
      config.params.company = this.$store.state.auth.user.company
      config.params.size = 5

      this.$utils
        .callAxios(
          codes.requests.getArticles.method,
          codes.requests.getArticles.url,
          config
        )
        .then(res => {
          // this.saveRequestConfig(res.config)
          if (res.data === '') {
            this.products = []
            this.productList = []
            this.totalPages = 0
            return
          }
          this.products = this.convertProductsDate(res.data.articleList)
          this.totalProducts = this.products.length
          this.pageInfoText = commons.getPageInfoText(res.headers)
          this.totalPages = Number(res.headers['x-totalpages'])
          this.selected = []
          this.productList = res.data.articleList
        })
        .catch(error => {
          this.products = []
          this.totalProducts = this.products.length
          const headers = {}
          headers['x-totalelements'] = 0
          this.pageInfoText = commons.getPageInfoText(headers)
          this.totalPages = 0
          this.selected = []
          console.debug(`Could not find any products. error: ${error}`)
        })
    },
    convertProductsDate (articleList) {
      return articleList.map(product => {
        product.lastUpdated = commons.convertDate(product.lastUpdated)
        return product
      })
    },
    getTemplateList () {
      this.selectTemplateIndex = ''
      const params = { store: this.$store.state.dataStore.selectedStore.code }
      const config = { params: params }
      params.company = this.$store.state.auth.user.company
      params.page = this.template.page
      this.searchTemplate.value !== null ? params.searchWord = this.searchTemplate.value : params.searchWord = ''
      params.linesPerPage = 6

      this.$utils
        .callAxios(
          dicamoApi.requests.getAssignTemplateList.method,
          dicamoApi.requests.getAssignTemplateList.url,
          config
        )
        .then(res => {
          if (res.data.responseCode === 200) {
            this.templateList = res.data.data
            this.template.total = res.data.total
          } else {
            this.templateList = []
            this.template.total = 0
          }
        })
        .catch(error => {
          this.templateList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`error: ${error}`)
        })
    },
    productConfirm () {
      // if (this.addProductList.length === 0 && this.mode !== 'edit') {
      //   EventBus.$emit('messageAlert', this.$t('No Product Added'))
      //   return
      // }
      const articleIds = []
      // if (this.mode === 'assign') {
      //   this.addProductList.forEach((item, index) => {
      //     item.index = index
      //     articleIds.push(item.articleId)
      //   })
      //   this.selectArticle = []
      //   this.step++
      // } else {
      this.addProductList.forEach((item, index) => {
        item.index = index
        articleIds.push(item.articleId)
      })
      this.selectArticle = this.addProductList.length > 0 ? this.addProductList[0] : ''
      this.step++
      // }
      this.getProductDetail(articleIds)
      if (this.addProductList.length > 0) this.$nextTick(() => setTimeout(() => this.selectTemplateProduct(this.addProductList[0], 1500)))
    },
    // updateAssign () {
    //   this.addProductList.forEach(item => {
    //     // const width = item.templateData === 'none' ? 120 : Number(item.templateData.width)
    //     const space = 1920 - item.templateData === 'none' ? 120 : Number(item.templateData.width) / (Number(this.addProductList.length) + 1)
    //     console.log(space)
    //     console.log(item.templateData)
    //     console.log(item.templateData.width)
    //     console.log(Number(this.addProductList.length) + 1)
    //     item.xposition = space
    //   })
    //   // this.step++
    // },
    confirmAssign () {
      // let list = []
      // if (this.step < 3) this.step++
      // const assign = new FormData()
      if (this.isLoading) return
      let assign = []
      let isTemplate = false

      if (this.lcdData.length > 0) {
        const article = []

        this.addProductList.forEach(item => {
          if (item.idx_template === 'none') {
            isTemplate = false
          } else {
            isTemplate = true
            article.push({
              article_id: item.articleId,
              idx_template: item.idx_template,
              xposition: item.xposition
            })
          }
        })

        if (!isTemplate && this.mode !== 'edit') {
          EventBus.$emit('messageAlert', this.$t('Do Not Enter Template by Article'))
          return
        }

        // console.log(JSON.stringify(article))

        // assign.append('company', this.$store.state.auth.user.company)
        // assign.append('store', this.$store.state.dataStore.selectedStore.code)
        // assign.append('uuid', this.lcdData[0].uuid)
        // assign.append('mac', this.lcdData[0].mac)
        // assign.append('nickname', this.lcdData[0].nickname)
        // assign.append('article', JSON.stringify(article))

        assign = {
          company: this.$store.state.auth.user.company,
          store: this.$store.state.dataStore.selectedStore.code,
          uuid: this.lcdData[0].uuid,
          mac: this.lcdData[0].mac,
          nickname: this.lcdData[0].nickname,
          article: article,
          timezone: this.$store.state.dataStore.timezone
        }

        if (this.fixTemplate) assign.fixTemplate = this.fixTemplate.idx_template

        if (assign.article.length === 0 && assign.fixTemplate) {
          EventBus.$emit('messageAlert', this.$t('Remove a fixed template or add products.'), 'error')
          return
        }

        // list = {
        //   company: this.$store.state.auth.user.company,
        //   store: this.$store.state.dataStore.selectedStore.code,
        //   uuid: this.lcdData[0].uuid,
        //   mac: this.lcdData[0].mac,
        //   nickname: this.lcdData[0].nickname,
        //   article: JSON.stringify(article)
        // }
        // console.log(list)
      } else {
        return
      }

      // const loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.formContainer,
      //   canCancel: false
      // })
      this.isLoading = true
      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.assignLcd.method,
          dicamoApi.requests.assignLcd.url,
          assign
        )
        .then(res => {
          // alert('완료')
          this.isLoading = false
          EventBus.$emit('messageAlert', this.$t('SUCCESS'))
          this.assignDialog = false
          this.initialize()
          // loader.hide()
          this.$emit('fireResetLcd')
          // this.closeEdit()
        })
        .catch(error => {
          this.isLoading = false
          console.debug(`error: ${error}`)
        })
    },
    closeAssign () {
      if (this.mode === 'assign') {
        this.addProductList.forEach((item, index) => {
          item.index = index
          item.idx_template = 'none'
          item.templatePreview = ''
          item.templateData = []
        })
        this.templateIndex = 0
        this.selectTemplateIndex = ''
        this.productIndex = ''
        this.selectArticle = []
      } else {
        this.templateIndex = 0
        this.selectTemplateIndex = ''
        this.productIndex = ''
        this.selectArticle = []
      }

      if (this.step === 1) {
        this.assignDialog = false
        this.$emit('fireResetLcd')
        this.initialize()
      } else {
        this.step--
      }
    },
    closeAssignNow () {
      this.assignDialog = false
      this.$emit('fireResetLcd')
      this.initialize()
    },
    openDialog () {
      this.assignDialog = true
    },
    clearEvent () {
      this.template.page = 1
      this.searchTemplate.value = null
      this.getTemplateList()
    },
    searchAnchors (searchType) {
      /**
       * 2022 - 03 - 14 추가
       * 검색 시 page 1로 초기화.
       */
      if (searchType === 'Product') {
        this.getProductList(1)
        // this.page = 1
        this.articlePage = 1
      }
      if (searchType === 'Template') {
        this.template.page = 1
        this.getTemplateList()
      }
    },
    stepClass (index) {
      if (this.step > index) return 'step-progressbar__item step-progressbar__item--complete'
      else if (this.step === index) return 'step-progressbar__item step-progressbar__item--active'
      else return 'step-progressbar__item'
    }
  },
  computed: {
    selectFixTemplate () {
      return this.fixTemplate ? this.fixTemplate.idx_template : -1
    },
    assignHeight () {
      let size = 0
      if (this.lcdData.length > 0) {
        if (this.lcdData[0].device_type === 'Newton Touch 23"' || Number(this.lcdData[0].device_type) === 23) size = 65
        else size = 225
      }
      return size
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    templateData () {
      return this.selectTemplate ? this.selectTemplate.template_data : ''
    }
    // templatePreviewArticle () {
    //   const list = []
    //   // const style = []
    //   // let barcode = ''
    //   if (this.addProductList.length === 0) return list
    //   if (this.addProductList[this.templateIndex].idx_template !== 'none') {
    //     this.addProductList[this.templateIndex].templateData.bind.forEach(item => {
    //       const articleId = item.column
    //       let article = 0
    //       // if (this.selectedArticle.length > 0) article = this.selectedArticle[this.templateIndex].data
    //       if (this.selectedArticle.length > 0) {
    //         this.selectedArticle.forEach(item => {
    //           if (item.articleId === this.selectArticle.articleId) article = item.data
    //         })
    //       }
    //       let text = ''
    //       let barcode = ''
    //       if (article) {
    //         for (const [key, value] of Object.entries(article)) {
    //           if (key === 'BAROCDE') barcode = value
    //           else if (key === articleId) {
    //             text = value
    //           }
    //         }
    //         list.push({
    //           text: text,
    //           barcode: barcode,
    //           style: {
    //             position: 'absolute',
    //             top: item.y + 'px',
    //             left: item.x + 'px',
    //             'text-align': item.align,
    //             color: item.fill,
    //             'font-family': item.fontFamily,
    //             'font-size': item.fontSize + 'px',
    //             'font-weight': item.fontStyle,
    //             'font-style': item.fontStyle,
    //             'text-stroke-width': item.strokeWidth > 1 ? '1px' : '0px',
    //             'text-stroke-color': item.stroke,
    //             width: item.width + 'px',
    //             height: item.height + 'px',
    //             overflow: 'hidden'
    //           }
    //         })
    //       }
    //     })
    //   } else if (this.selectTemplate && this.selectTemplateIndex !== '') {
    //     // console.log(this.selectTemplate.template_data)
    //     this.selectTemplate.template_data.bind.forEach(item => {
    //       const articleId = item.column
    //       let article = 0
    //       if (this.selectedArticle.length > 0) {
    //         this.selectedArticle.forEach(item => {
    //           if (item.articleId === this.selectArticle.articleId) article = item.data
    //         })
    //       }
    //       let text = ''
    //       let barcode = ''
    //       if (article) {
    //         for (const [key, value] of Object.entries(article)) {
    //           if (key === 'BAROCDE') barcode = value
    //           else if (key === articleId) {
    //             text = value
    //           }
    //         }
    //         list.push({
    //           text: text,
    //           barcode: barcode,
    //           style: {
    //             position: 'absolute',
    //             top: item.y + 'px',
    //             left: item.x + 'px',
    //             'text-align': item.align,
    //             color: item.fill,
    //             'font-family': item.fontFamily,
    //             'font-size': item.fontSize + 'px',
    //             'font-weight': item.fontStyle,
    //             'font-style': item.fontStyle,
    //             'text-stroke-width': item.strokeWidth > 1 ? '1px' : '0px',
    //             'text-stroke-color': item.stroke,
    //             width: item.width + 'px',
    //             height: item.height + 'px',
    //             overflow: 'hidden'
    //           }
    //         })
    //       }
    //     })
    //   } else {

    //   }
    //   return list
    // }
  }
}
</script>

<style scoped>
  /* ::v-deep tr.v-data-table__selected {
    background: #797979 !important;
  }
  ::v-deep tr.v-data-table__selected > td {
    color: white !important;
  } */

    #draggable-container {
      position: absolute;
      z-index: 9;
    }
    #draggable-header {
      z-index: 10;
    }
  .timecell-event {
    background-color: #e27070;
    height: 38px;
    width: 42px;
    display: block;
    z-index: 999;
    margin-left: 3px;
  }
  .selected {
    background: #797979 !important;
  }
  .select_text {
    color: white !important;
  }
  .selected.products:hover > td {
    color: black !important;
  }
  ::v-deep .barcode {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 180px !important;
    height: 20px !important;
  }
  /* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
