var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "rounded-0",
      attrs: { width: "512", scrollable: "", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn",
                      attrs: { disabled: _vm.btnDisabledRegistration, text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Registration")) + " ")]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.registDialog,
        callback: function($$v) {
          _vm.registDialog = $$v
        },
        expression: "registDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c(
              "h3",
              {
                staticClass: "page-title-bar",
                staticStyle: { "margin-bottom": "0px" }
              },
              [
                _c("i", { staticClass: "ico ico-lcd-list" }),
                _vm._v(_vm._s(_vm.$t("Registration")))
              ]
            )
          ]),
          _c(
            "v-card-text",
            { staticClass: "px-9", staticStyle: { padding: "0!important" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { staticClass: "mt-10", attrs: { cols: "4" } }, [
                    _c("p", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Model")) + " "),
                      _c("span", { staticClass: "redbold" }, [_vm._v("*")])
                    ])
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "mt-10", attrs: { cols: "7" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "form-select pt-0",
                        attrs: {
                          items: _vm.defaultModel,
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.form.model,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "model", $$v)
                          },
                          expression: "form.model"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { staticClass: "mt-10", attrs: { cols: "4" } }, [
                    _c("p", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("UUID")) + " "),
                      _c("span", { staticClass: "redbold" }, [_vm._v("*")])
                    ])
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "mt-10", attrs: { cols: "7" } },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input pt-0",
                        attrs: {
                          placeholder: _vm.$t("Input UUID"),
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.form.uuid,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "uuid", $$v)
                          },
                          expression: "form.uuid"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { staticClass: "mt-10", attrs: { cols: "4" } }, [
                    _c("p", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("MAC Address")) + " "),
                      _c("span", { staticClass: "redbold" }, [_vm._v("*")])
                    ])
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "mt-10", attrs: { cols: "7" } },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input pt-0",
                        attrs: {
                          placeholder: _vm.$t("Input MAC Address"),
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          clearable: ""
                        },
                        on: { input: _vm.macCheck, change: _vm.changeMac },
                        model: {
                          value: _vm.form.mac,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "mac", $$v)
                          },
                          expression: "form.mac"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { staticClass: "mt-10", attrs: { cols: "4" } }, [
                    _c("p", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Nickname")) + " "),
                      _c("span", { staticClass: "redbold" }, [_vm._v("*")])
                    ])
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "mt-10", attrs: { cols: "7" } },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input pt-0",
                        attrs: {
                          placeholder: _vm.$t("Input Nickname"),
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.form.nickname,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "nickname", $$v)
                          },
                          expression: "form.nickname"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: {
                    text: "",
                    icon: "",
                    disabled:
                      _vm.form.model === "" ||
                      _vm.form.uuid === "" ||
                      _vm.form.mac.trim().length < 17 ||
                      _vm.form.nickname === ""
                  },
                  on: { click: _vm.insertLcdList }
                },
                [_vm._v(_vm._s(_vm.$t("Register")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      _vm.registDialog = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }