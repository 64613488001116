<template>
  <v-dialog v-model="mappingDialog" width="1080" scrollable persistent>
    <template v-slot:activator="{ attrs }">
      <v-btn
        v-bind="attrs"
        :disabled="btnDisabledMapping"
        @click="openMapping"
        text
        class="btn btn-danger"
        >{{ $t('Playlist Group Mapping') }}
      </v-btn>
    </template>
    <v-card class="popup add_store_popup">
      <v-card-title><h3 class="page-title-bar"><i class="ico ico-lcd-list"></i>{{$t('Playlist Group Mapping')}}</h3></v-card-title>
      <v-card-text class="pa-0">
        <v-row>
          <v-col cols="4">
            <v-subheader class="sub-title font-weight-bold px-0">{{$t('Selected LCD')}}</v-subheader>
            <div class="simple-line-table only-line">
              <v-simple-table fixed-header height="228px">
                <template v-slot:default>
                  <colgroup>
                    <col width="50%"/>
                    <col width="50%"/>
                  </colgroup>
                  <thead>
                    <tr>
                      <th class="indent">{{$t('NICKNAME')}}</th>
                      <th class="indent">{{$t('MAC ID')}}</th>
                    </tr>
                  </thead>
                    <tbody v-if="selectList.length > 0">
                      <tr v-for="item in selectList" :key="item.index">
                        <td class="indent"> {{ item.nickname }} </td>
                        <td class="indent"> {{ item.mac }} </td>
                      </tr>
                    </tbody>
                </template>
              </v-simple-table>
            </div>

            <v-subheader class="sub-title font-weight-bold px-0  mt-5 pt-5">{{$t('Schedule')}}</v-subheader>
            <div class="form-group mb-3 mt-7">
              <!-- <label>Start Date</label> -->
              <p style="position: absolute;margin-bottom: 66px;font-size: 12px;">
                    {{$t('Start Date')}}
                    <!-- <span class="redbold">*</span> -->
                  </p>
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  :close-on-content-click="false"
                  :return-value.sync="form.start_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hide-details=""
                      v-model="startDateText"
                      append-icon="mdi-calendar"
                      readonly
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      style="word-spacing: 0px;"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="form.start_date"
                    :min="today"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="startMenu = false"
                    >
                      {{$t('Cancel')}}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startMenu.save(form.start_date)"
                    >
                      {{$t('OK')}}
                    </v-btn>
                  </v-date-picker>
                </v-menu>

            </div>
            <div class="form-group mt-6">
              <p style="position: absolute;margin-bottom: 66px;font-size: 12px;">
                    {{$t('End Date')}}
                    <!-- <span class="redbold">*</span> -->
                  </p>
                <v-menu
                  ref="endMenu"
                  v-model="endMenu"
                  :close-on-content-click="false"
                  :return-value.sync="form.end_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hide-details=""
                      v-model="endDateText"
                      append-icon="mdi-calendar"
                      readonly
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      style="word-spacing: 0px;"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.end_date"
                    no-title
                    :min="today"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="endMenu = false"
                    >
                      {{$t('Cancel')}}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endMenu.save(form.end_date)"
                    >
                      {{$t('OK')}}
                    </v-btn>
                  </v-date-picker>
                </v-menu>

            </div>

          </v-col>
          <v-col cols="8">
            <v-subheader class="sub-title font-weight-bold px-0">{{$t('Playlist Group')}}</v-subheader>
            <v-data-table
                v-if="mappingDialog"
                @click:row="selectPlaylist"
                :items-per-page="Number(1000000)"
                :headers="headers"
                :items="playlistData"
                single-select
                item-key="idx_playlist_set"
                hide-default-footer
                class="gray-data-table"
                height="470"
                fixed-header
              >
              </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon class="btn" @click="mappingLcd()">{{$t('Mapping')}}</v-btn>
        <v-btn text icon @click="closeMapping()" class="btn">{{$t('Close')}}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="isLoading" persistent>
      <v-progress-circular
        style="width:100%;"
        indeterminate
        :size="50"
        :width="7"
        color="#ddd"
      ></v-progress-circular>
    </v-dialog>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import dicamoApi from '@/lcd/plugins/dicamoApi'
// import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  props: {
    btnDisabledMapping: Boolean,
    user: Object,
    selectList: Array,
    deviceType: Array
  },
  name: 'mappingDialog',
  data () {
    return {
      mappingBtnDisabled: false,
      mappingDialog: false,
      startMenu: false,
      endMenu: false,
      isLoading: false,
      form: {
        start_date: '',
        end_date: '',
        idx_playlist_set: ''
      },
      headers: [
        { text: this.$t('PLAYLIST GROUP'), align: 'start', sortable: true, value: 'title' },
        { text: this.$t('TIME'), align: 'start', sortable: true, value: 'running_time' },
        { text: this.$t('LAST MODIFIED DATE'), align: 'start', sortable: true, value: 'w_time' }
      ],
      playlistData: []
    }
  },
  mounted () {
    this.getPlaylistGroup()
  },
  computed: {
    selectIndex () {
      const indexList = []
      this.selectList.forEach(item => {
        indexList.push(item.idx_lcd)
      })
      return indexList
    },
    startDateText () {
      return '  ' + this.form.start_date.split('-').join('  -  ')
    },
    endDateText () {
      return '  ' + this.form.end_date.split('-').join('  -  ')
    },
    today () {
      const date = new Date()
      return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0')
    }
  },
  watch: {
    mappingDialog: {
      handler () {
        if (this.mappingDialog) this.getPlaylistGroup()
      }
    }
  },
  methods: {
    openMapping () {
      const set = new Set(this.deviceType)
      if (set.size === 1) {
        this.mappingDialog = true
        return true
      } else {
        EventBus.$emit('messageAlert', this.$t('Selected model size is different'))
        this.$emit('fireResetLcd')
        this.form = {
          start_date: '',
          end_date: '',
          idx_playlist_set: ''
        }
        this.mappingDialog = false
        return false
      }
    },
    getDate (item) {
      const date = new Date(item)
      // let dateText = commons.convertDate(date)
      // dateText = dateText.split('-')

      // return dateText[0] + dateText[1] + dateText[2]
      return commons.convertDate(date)
    },
    selectPlaylist (item, row) {
      row.select(true)
      this.form.idx_playlist_set = item.idx_playlist_set
    },
    getTime (minute) {
      const cal = minute / 60
      const time = ((Math.floor(cal) + '').padStart(2, '0') + ':' + (minute % 60 + '').padStart(2, '0'))
      return time
    },
    getPlaylistGroup () {
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'list')
      data.append('timezone', this.$store.state.dataStore.timezone)
      // data.append('total', 'all')
      data.append('limit', dicamoApi.EXPORT_SIZE)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getPlaylist.method,
          dicamoApi.requests.getPlaylist.url,
          data
        )
        .then(res => {
          // this.playlist = res.data.data
          this.getPlaylistData(res.data.data)
        })
        .catch(error => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any playlist. error: ${error}`)
        })
    },
    getPlaylistData (items) {
      this.playlistData = []
      items.forEach(item => {
        const playlistSet = JSON.parse(item.playlist_set)
        if (String(this.deviceType[0]) === String(item.device_type)) {
          const data = {
            idx_playlist_set: item.idx_playlist_set,
            category: item.category,
            device_type: item.device_type,
            title: item.title,
            start_time: this.getTime(playlistSet.length > 0 ? playlistSet[0].startTime : ''),
            end_time: this.getTime(playlistSet.length > 0 ? playlistSet[playlistSet.length - 1].endTime : ''),
            running_time: this.getTime(playlistSet.length > 0 ? playlistSet[playlistSet.length - 1].endTime - playlistSet[0].startTime : ''),
            w_time: this.getDate(item.w_time)
          }
          this.playlistData.push(data)
        }
      })
    },
    closeMapping () {
      this.$emit('fireResetLcd')
      this.form = {
        start_date: '',
        end_date: '',
        idx_playlist_set: ''
      }
      this.mappingDialog = false
    },
    mappingLcd () {
      const data = new FormData()
      if (!commons.notEmpty(this.selectList)) {
        EventBus.$emit('messageAlert', this.$t('No Selected LCD List'))
        return
      }
      if (!commons.notEmpty(this.form.idx_playlist_set)) {
        EventBus.$emit('messageAlert', this.$t('No Selected Playlist'))
        return
      }
      if (!commons.notEmpty(this.form.start_date) || !commons.notEmpty(this.form.end_date)) {
        EventBus.$emit('messageAlert', this.$t('Date not Select'))
        return
      }
      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('idx_lcds', JSON.stringify(this.selectIndex))
      data.append('idx_playlist_set', this.form.idx_playlist_set)
      data.append('start_date', this.form.start_date)
      data.append('end_date', this.form.end_date)
      data.append('timezone', this.$store.state.dataStore.timezone)
      data.append('type', 'schedule')

      this.isLoading = true
      // const loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.formContainer,
      //   canCancel: false
      // })

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.postScheduleMappingList.method,
          dicamoApi.requests.postScheduleMappingList.url,
          data
        )
        .then(res => {
          // this.playlist = res.data.data
          this.isLoading = false
          EventBus.$emit('messageAlert', this.$t('SUCCESS'))
          this.closeMapping()
          this.$emit('fireResetLcd')
          // loader.hide()
        })
        .catch(error => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any playlist. error: ${error}`)
          this.isLoading = false
          // loader.hide()
        })
    }
  }
}
</script>

<style scoped>
  ::v-deep tr.v-data-table__selected {
    background: #797979 !important;
    color: white;
  }
  ::v-deep .v-text-field__slot > input {
    word-spacing: 15px;
  }
  .indent {
    text-indent: 20px;
  }
  ::v-deep .v-data-table-header > tr > th {
    background: #ececec !important
  }
  /* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
