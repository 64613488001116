<template>
  <div class="home">
    <h2 class="page-title-bar">
      <i class="ico ico-lcd-list"></i>{{ $t("LCD List") }}
    </h2>
    <section class="searchArea align-end" style="width: 100%">
      <div class="form-group form-inline" :style="{ width: '17%' }">
        <v-text-field
          class="form-input"
          v-model="searchBy.nickname"
          :placeholder="$t('Input nickname')"
          :label="$t('Nickname')"
          @keyup.enter="searchLcdList"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
      </div>
      <div class="form-group form-inline ml-3" :style="{ width: '22%' }">
        <v-text-field
          v-model="searchBy.mac"
          :placeholder="$t('Input mac')"
          class="form-input"
          :label="$t('MAC')"
          @keyup.enter="searchLcdList"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
      </div>
      <!-- <div class="form-group form-inline ml-3" :style="{ width: '22%' }">
        <label>UUID</label>
        <v-text-field
          v-model="searchBy.uuid"
          :placeholder="$t('Input UUID')"
          class="form-input"
          @keyup.enter="searchLcdList"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
      </div> -->
      <div class="form-group form-inline ml-3" :style="{ width: '22%' }">
        <v-combobox
          dense
          outlined
          v-model="searchBy.model"
          :items="modelList"
          :label="$t('Model')"
          class="form-select ml-3"
          :placeholder="$t('Select model')"
          hide-details
          clearable
        ></v-combobox>
      </div>
      <div class="form-group form-inline ml-3" :style="{ width: '22%' }">
        <v-text-field
          v-model="searchBy.playlist_group"
          @keyup.enter="searchLcdList"
          :placeholder="$t('Input playlist group name')"
          class="form-input"
          :label="$t('Playlist Group')"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
      </div>
      <!-- <div class="form-group form-inline ml-3" :style="{ width: '13%' }">
        <label>Activation</label>
        <v-combobox
          :items="comboitems2"
          :placeholder="$t('Select Access')"
          class="form-combobox"
          hide-details
          solo
          outlined
          ></v-combobox>
      </div> -->
      <div class="form-group form-inline ml-3" :style="{ width: '17%' }">
        <v-combobox
          v-model="searchBy.access"
          :label="$t('Access')"
          :placeholder="$t('Select access')"
          :items="actionList"
          class="form-select"
          outlined
          dense
          clearable
          hide-details
        ></v-combobox>
      </div>
      <v-btn
        :style="{ width: '12%' }"
        @click="searchLcdList()"
        class="btn type-search ml-3"
        text
        >{{ $t("Search") }}
      </v-btn>
    </section>

    <!-- LCD list -->
    <v-data-table
      v-model="selected"
      item-key="idx_lcd"
      :options.sync="options"
      :headers="lcdlistHeaders"
      :items="lcdlist"
      :hide-default-footer="true"
      show-select
      class="tbl-type01 mt-10"
    >
      <!-- <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                v-for="item in headers"
                :key="item.index">
                {{ item.text }}<v-icon small>mdi-arrow-up</v-icon>
              </th>
            </tr>
          </thead>
        </template> -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.scheduleD="{ item }">
        <a
          href=""
          type="button"
          @click.prevent="showScheduleDialog(item.idx_lcd)"
          ><i class="ico ico-calendar"></i
        ></a>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.access="{ item }">
        <v-icon :color="item.access === '1' ? '#C8EEBB' : '#E27070'"
          >mdi-circle</v-icon
        >
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.mac="{ item }">
        <td>
          <a href="" @click.prevent="showLcdDetail(item)"> {{ item.mac }} </a>
        </td>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.uuid="{ item }">
        <td>
          <a href="" @click.prevent="showLcdDetail(item)"> {{ item.uuid }} </a>
        </td>
      </template>
      <template slot="no-data">
        <p>
          {{ $t("No data available") }}
        </p>
      </template>
    </v-data-table>

    <!-- button group -->
    <div class="table-options">
      <div>
        <div class="d-block mb-2">
          <playlist-group-mapping
            @fireResetLcd="resetLcd"
            :deviceType="deviceType"
            :selectList="selected"
            :btnDisabledMapping="mappingBtnDisabled"
            :user="user"
          />
          <assign-dialog
            @fireResetLcd="resetLcd"
            :lcdData="selected"
            :btnDisabledAssign="assignBtnDisabled"
            :user="user"
            :mode="'edit'"
            :btype="'assign'"
          />
          <v-btn
            v-if="isLocal !== 'cloud'"
            @click="lcdRebootBtn"
            :disabled="deleteBtnDisabled"
            text
            class="btn ml-3 btn-danger"
            >{{ $t("Reboot") }}
          </v-btn>
          <v-btn
            v-if="isLocal !== 'cloud'"
            @click="lcdResetBtn"
            :disabled="deleteBtnDisabled"
            text
            class="btn ml-3 btn-danger"
            >{{ $t("Reset") }}
          </v-btn>
        </div>
        <registration-dialog
          :btnDisabledRegistration="addBtnDisabled"
          :user="user"
          @fireResetLcd="resetLcd"
        />
        <edit-dialog
          :btnDisabledEdit="editBtnDisabled"
          :user="user"
          :item="selected"
          @fireResetLcd="resetLcd"
        />
        <v-btn
          @click="deleteDialog = true"
          :disabled="deleteBtnDisabled"
          text
          class="btn ml-3"
          >{{ $t("Delete") }}
        </v-btn>
        <v-btn
          @click="exportLcdList()"
          text
          :disabled="exportBtnDisabled"
          class="btn ml-3"
          >{{ $t("Export") }}
        </v-btn>
      </div>
      <!-- paging -->
      <!-- paging -->
      <div class="mt-8">
        <div class="pageInfo">{{ pageInfoText }}</div>
        <div class="pagination">
          <!-- 페이지 앞 뒤 이동 -->
          <button
            @click="goTenPageBackwards(page, paging)"
            :class="page < 10 ? 'pagination-nav-disabled' : null"
            class="pagination-nav pagination-nav-prev"
            type="button"
          >
            <img
              src="@/assets//img/ico-paging-prev.png"
              alt="이전 10페이지 이동"
            />
          </button>
          <v-pagination
            v-model="page"
            @input="paging"
            :length="totalPages * 1 || 1"
            :total-visible="10"
            color="#2f3b4c"
            style="display: block"
          ></v-pagination>
          <button
            @click="goTenPageForward(page, paging, totalPages)"
            :class="page + 10 > totalPages ? 'pagination-nav-disabled' : null"
            class="pagination-nav pagination-nav-next"
            type="button"
          >
            <img
              src="@/assets//img/ico-paging-next.png"
              alt="다음 10페이지 이동"
            />
          </button>

          <div class="goto-Page d-flex align-center">
            <label>{{$t('Go to')}}</label>
            <v-text-field
              v-model="goToPageInput"
              class="form-input noneline"
              placeholder="Page"
              outlined
              dense
              hide-details
              solo
              @keyup.enter="goToPage(goToPageInput, paging, totalPages)"
            ></v-text-field>

            <!-- <button
                @click="goToPage(goToPageInput, paging, totalPages)"
                type="button"
                class="btnGoToPage"
              >
                <i
                  class="v-icon notranslate mdi mdi-chevron-right theme--light"
                  aria-hidden="true"
                >
                </i>
              </button> -->
          </div>
        </div>
      </div>
    </div>
    <LCDDetail
      :user="user"
      :showLcdDetail="showLcdDetailDisabled"
      @fireResetLcd="resetLcd"
    />
    <schedule-dailog
      :user="user"
      :showSchedule="showScheduleDisabled"
      @fireResetLcd="resetLcd"
    />
    <v-dialog v-model="deleteDialog" width="370">
      <v-card class="popup-success">
        <!-- <i class="ico-error"
          ><img src="@/assets/img/ico-unassign.png" alt=""
        /></i> -->
        <i class="ico-success"
          ><img src="@/assets/img/ico-caution.png" alt=""
        /></i>
        <div class="msg">
          <h3>{{ $t("Delete") }}</h3>
          <p>{{ $t("Are you sure you want to delete?") }}</p>
        </div>
        <v-card-actions class="d-flex justify-center btnArea">
          <v-btn text icon class="btn" @click="deleteLcd">{{
            $t("Yes")
          }}</v-btn>
          <v-btn text icon @click="deleteDialog = false" class="btn">{{
            $t("No")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="lcdResetDialog" width="370">
      <v-card class="popup-success">
        <i class="ico-success"
          ><img src="@/assets/img/ico-caution.png" alt=""
        /></i>
        <div class="msg">
          <h3>{{ $t("Reset") }}</h3>
          <p>{{ $t("Are you sure you want lcd to reset?") }}</p>
        </div>
        <v-card-actions class="d-flex justify-center btnArea">
          <v-btn text icon class="btn" @click="resetLcdItem">{{
            $t("Yes")
          }}</v-btn>
          <v-btn text icon @click="lcdResetDialog = false" class="btn">{{
            $t("No")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="lcdRebootDialog" width="370">
      <v-card class="popup-success">
        <i class="ico-success"
          ><img src="@/assets/img/ico-caution.png" alt=""
        /></i>
        <div class="msg">
          <h3>{{ $t("Reboot") }}</h3>
          <p>{{ $t("Are you sure you want to lcd reboot?") }}</p>
        </div>
        <v-card-actions class="d-flex justify-center btnArea">
          <v-btn text icon class="btn" @click="rebootLcdItem">{{
            $t("Yes")
          }}</v-btn>
          <v-btn text icon @click="lcdRebootDialog = false" class="btn">{{
            $t("No")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dicamoApi from '@/lcd/plugins/dicamoApi'
import commons from '@/plugins/commons'
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
import RegistrationDialog from './modal/RegistrationDialog.vue'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import AssignDialog from './modal/AssignDialog.vue'
import LCDDetail from './modal/LCDDetail.vue'
import ScheduleDailog from './modal/ScheduleDailog.vue'
import PlaylistGroupMapping from './modal/PlaylistGroupMapping.vue'
import EditDialog from './modal/EditDialog.vue'
import XLSX from 'xlsx'
import Pagenation from '@/mixins/Pagenation'
// import SleepSchedule from './modal/SleepSchedule.vue'

export default {
  name: 'DicamoLCDList',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    RegistrationDialog,
    AssignDialog,
    LCDDetail,
    ScheduleDailog,
    PlaylistGroupMapping,
    EditDialog
    // SleepSchedule
  },
  mixins: [Pagenation],
  data () {
    return {
      // TODO 임시테스트용---------------
      setImage: false,
      idx_template: '',
      xposition: 0,
      idx_lcd: '',
      // --------------------------
      // deleteDialog: false,
      lcdlistHeaders: [
        {
          text: this.$t('NICKNAME'),
          value: 'nickname',
          width: '15%',
          align: 'start'
        },
        { text: this.$t('MAC'), value: 'mac', width: '20%', align: 'start' },
        // { text: this.$t('UUID'), value: 'uuid', width: '20%', align: 'start' },
        {
          text: this.$t('MODEL'),
          value: 'device_type',
          width: '15%',
          align: 'start'
        },
        {
          text: this.$t('PLAYLIST GROUP'),
          value: 'playlist_set_title',
          width: '15%',
          align: 'start'
        },
        // { text: this.$t('TEMPLATE'), value: 'template', width: '12%' },
        // { text: this.$t('ACTIVATION'), value: 'activation', width: '10%' },
        {
          text: this.$t('ACCESS'),
          value: 'access',
          width: '15%',
          align: 'start'
        },
        {
          text: this.$t('SCHEDULE'),
          value: 'scheduleD',
          width: '15%',
          sortable: false,
          align: 'start'
        }
        // { text: this.$t('TEST'), value: 'btn_template', width: '10%', sortable: false, align: 'start' }
      ],
      exportOption: {
        nickname: '',
        uuid: '',
        mac: '',
        model: '',
        playlist: '',
        access: '',
        type: ''
      },
      isLocal: process.env.VUE_APP_PRODUCTION_TYPE,
      lcdResetDialog: false,
      lcdRebootDialog: false,
      btnDisabledMappingAssign: false,
      btnDisabledRegistration: false,
      btnDisabledEditDelete: false,
      btnDisabledSleepSchedule: false,
      showLcdDetailDisabled: false,
      showScheduleDisabled: false,
      btnDisabledExport: false,
      goToPageInput: null,
      deleteDialog: false,
      selected: [],
      user: null,
      options: {},
      searchBy: {
        nickname: null,
        mac: null,
        uuid: null,
        model: null,
        playlist_group: null,
        // touch_template: null,
        // activation: null,
        access: null
      },
      search: {
        by: 'getByPosition',
        value: null
      },
      lcdlist: [],
      actionList: [
        {
          text: 'Off',
          value: 0
        },
        {
          text: 'On',
          value: 1
        }
      ],
      modelList: [
        {
          text: 'Newton Touch 23"',
          value: 23
        },
        {
          text: 'Newton Touch 29"',
          value: 29
        },
        {
          text: 'FHD Box',
          value: 'FHD'
        },
        {
          text: 'Tablet',
          value: 'tablet'
        }
      ],
      page: 1
    }
  },
  mounted () {
    this.exportOption.type = this.type
    if (this.type === 'offline') {
      this.searchBy.access = 'Off'
      this.exportOption.access = 'Off'
    }
    // LCD PlaylistGroup Mapping / Assigng
    this.$store.dispatch('auth/getDisabledBtn', '22001').then((flag) => {
      this.btnDisabledMappingAssign = flag
    })
    // LCD Registration
    this.$store.dispatch('auth/getDisabledBtn', '22002').then((flag) => {
      this.btnDisabledRegistration = flag
    })
    // LCD Edit / Delete
    this.$store.dispatch('auth/getDisabledBtn', '22003').then((flag) => {
      this.btnDisabledEditDelete = flag
    })
    // LCD Sleep Schedule
    this.$store.dispatch('auth/getDisabledBtn', '22004').then((flag) => {
      this.btnDisabledSleepSchedule = flag
    })
    // LCD DetailPopup
    this.$store.dispatch('auth/getDisabledBtn', '22005').then((flag) => {
      this.showLcdDetailDisabled = flag
    })
    // LCD Schedule Calendar
    this.$store.dispatch('auth/getDisabledBtn', '22006').then((flag) => {
      this.showScheduleDisabled = flag
    })
    // LCD Export
    this.$store.dispatch('auth/getDisabledBtn', '22007').then((flag) => {
      this.btnDisabledExport = flag
    })

    EventBus.$emit('enableSelectedStores', true)
    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.getLcdList()
    }
  },
  computed: {
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    deviceType () {
      const model = []
      if (this.selected.length > 0) {
        this.selected.forEach((item) => {
          if (item.device_type === 'Newton Touch 23"') model.push(23)
          else if (item.device_type === 'Newton Touch 29"') model.push(29)
          else if (item.device_type === 'FHD Box') model.push('FHD')
          else if (item.device_type === 'Tablet') model.push('Tablet')
        })
      }
      return model
    },
    totalRecords () {
      let count = 0
      if (!commons.isNull(this.lcdlist) && this.lcdlist.length > 0) {
        count = this.lcdlist[0].total
      }
      return count
    },
    totalPages () {
      return Math.ceil(this.totalRecords / 10)
    },
    pageInfoText () {
      // return ''
      const curPage = (this.page - 1) * 1 * 10 + 1
      const curPageTo = (this.page - 1) * 10 + this.lcdlist.length * 1
      // if (this.totalPages !== this.page) curPageTo++
      return (
        curPage + this.$t(' to ') + curPageTo + ', ' + this.totalRecords + this.$t('in total')
      )
      // return '0 to 0, 0'
    },
    addBtnDisabled () {
      return this.btnDisabledRegistration || !this.store.code
    },
    editBtnDisabled () {
      return this.btnDisabledEditDelete || this.selected.length !== 1
    },
    deleteBtnDisabled () {
      return this.btnDisabledEditDelete || this.selected.length < 1
    },
    sleepSchduleBtnDisabled () {
      return this.btnDisabledSleepSchedule || !this.store.code
    },
    mappingBtnDisabled () {
      return this.btnDisabledMappingAssign || this.selected.length < 1
    },
    assignBtnDisabled () {
      return this.btnDisabledMappingAssign || this.selected.length !== 1
    },
    exportBtnDisabled () {
      let btnDisabled = this.btnDisabledExport
      if (!btnDisabled) {
        btnDisabled = this.lcdlist !== undefined && this.lcdlist.length < 1
      }
      return btnDisabled
    }
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  methods: {
    lcdRebootBtn () {
      this.lcdRebootDialog = true
    },
    lcdResetBtn () {
      this.lcdResetDialog = true
    },
    // TODO : 임시 테스트용 메서드
    // test (item) {
    //   this.setImage = true
    //   this.idx_lcd = item.idx_lcd
    // },
    // testImage () {
    //   const form = new FormData()
    //   form.append('company', this.$store.state.auth.user.company)
    //   form.append('store', this.$store.state.dataStore.selectedStore.code)
    //   form.append('idx_template', this.idx_template)
    //   form.append('xposition', this.xposition)
    //   form.append('idx_lcd', this.idx_lcd)

    //   this.$utils
    //     .callAxiosWithBody(
    //       dicamoApi.requests.test.method,
    //       dicamoApi.requests.test.url,
    //       form
    //     )
    //     .then(res => {
    //       console.log(res)
    //       // return res.data.data
    //     })
    //     .catch(() => {
    //       // return []
    //       // this.totalMedia = this.mediaDataList[0].length
    //       // console.debug(`Could not find any playlist. error: ${error}`)
    //     })
    // },
    // --------------------------
    getLcdData (array) {
      array.forEach((item) => {
        if (item.device_type === '23') item.device_type = 'Newton Touch 23"'
        else if (item.device_type === '29') item.device_type = 'Newton Touch 29"'
        else if (item.device_type === 'FHD') item.device_type = 'FHD Box'
        else if (item.device_type === 'Tablet') item.device_type = 'Tablet'
        else item.device_type = ''
      })
      this.lcdlist = array
    },
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    resetLcd () {
      this.getLcdList()
      // this.page = 1
      this.selected = []
    },
    searchLcdList () {
      this.type = ''
      this.page = 1
      this.selected = []

      this.exportOption.nickname = ''
      this.exportOption.mac = ''
      this.exportOption.model = ''
      this.exportOption.playlist = ''
      this.exportOption.access = ''
      this.exportOption.type = ''

      if (!commons.isNull(this.searchBy.nickname)) {
        this.exportOption.nickname = this.searchBy.nickname
      }
      // if (!commons.isNull(this.searchBy.mac)) this.exportOption.mac = this.searchBy.mac
      if (!commons.isNull(this.searchBy.uuid)) {
        this.exportOption.uuid = this.searchBy.uuid
      }
      if (!commons.isNull(this.searchBy.model)) {
        this.exportOption.model = this.searchBy.model.value
      }
      if (!commons.isNull(this.searchBy.playlist_group)) {
        this.exportOption.playlist = this.searchBy.playlist_group
      }
      // if (!commons.isNull(this.searchBy.touch_template))data.append('sc_touch_template', this.searchBy.touch_template)
      if (!commons.isNull(this.searchBy.access)) {
        this.exportOption.access =
          this.searchBy.access.value === 1 ? 'true' : 'false'
      }
      this.getLcdList()
    },
    async showLcdDetail (index) {
      if (this.showLcdDetailDisabled) return

      EventBus.$emit('showLcdDetail', index)
    },
    async showScheduleDialog (index) {
      if (this.showScheduleDisabled) return
      // this.lcdItem = this.getLcdItem(index)

      EventBus.$emit('showScheduleDialog', index)
    },
    getExportLcdList () {
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'list')
      data.append('limit', dicamoApi.EXPORT_SIZE)
      data.append('timezone', this.$store.state.dataStore.timezone)

      if (commons.notEmpty(this.exportOption.nickname)) {
        data.append('sc_nickname', this.exportOption.nickname)
      }
      if (commons.notEmpty(this.exportOption.mac)) {
        data.append('sc_mac', this.exportOption.mac)
      }
      // if (commons.notEmpty(this.exportOption.uuid))data.append('sc_uuid', this.exportOption.uuid)
      if (commons.notEmpty(this.exportOption.model)) {
        data.append('sc_model', this.exportOption.model)
      }
      if (commons.notEmpty(this.exportOption.playlist)) {
        data.append('sc_playlist_group', this.exportOption.playlist)
      }
      // if (!commons.notEmpty(this.searchBy.touch_template))data.append('sc_touch_template', this.searchBy.touch_template)
      if (commons.notEmpty(this.exportOption.access)) {
        data.append('sc_access', this.exportOption.access)
      }
      if (
        commons.notEmpty(this.exportOption.type) &&
        this.exportOption.type !== 'offline'
      ) {
        this.exportOption.type === 'scheduled'
          ? data.append('sc_assigned', 'Y')
          : data.append('sc_assigned', 'N')
      }

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getLcdList.method,
          dicamoApi.requests.getLcdList.url,
          data
        )
        .then((res) => {
          const json = this.exportTable(res.data.data)

          const excel = XLSX.utils.json_to_sheet(json)

          var wb = XLSX.utils.book_new()

          XLSX.utils.book_append_sheet(wb, excel, 'lcd')

          XLSX.writeFile(wb, 'LCDList.xlsx')
          // return res.data.data
        })
        .catch(() => {
          // return []
          // this.totalMedia = this.mediaDataList[0].length
          // console.debug(`Could not find any playlist. error: ${error}`)
        })
    },
    getLcdList () {
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'list')
      data.append('page', this.page)
      data.append('timezone', this.$store.state.dataStore.timezone)

      if (this.type === 'noscheduled') data.append('sc_assigned', 'N')
      if (this.type === 'scheduled') data.append('sc_assigned', 'Y')
      if (!commons.isNull(this.searchBy.nickname)) {
        data.append('sc_nickname', this.searchBy.nickname)
      }
      if (!commons.isNull(this.searchBy.mac)) {
        data.append('sc_mac', this.searchBy.mac)
      }
      // if (!commons.isNull(this.searchBy.uuid))data.append('sc_uuid', this.searchBy.uuid)
      if (!commons.isNull(this.searchBy.model)) {
        data.append('sc_model', this.searchBy.model.value)
      }
      if (!commons.isNull(this.searchBy.playlist_group)) {
        data.append('sc_playlist_group', this.searchBy.playlist_group)
      }
      // if (!commons.isNull(this.searchBy.touch_template))data.append('sc_touch_template', this.searchBy.touch_template)
      if (!commons.isNull(this.searchBy.access)) {
        data.append(
          'sc_access',
          this.searchBy.access.value === 1 ? 'true' : 'false'
        )
      }

      if (!commons.isNull(this.options.sortBy)) {
        data.append('order', this.options.sortBy)
      }
      if (!commons.isNull(this.options.sortDesc)) {
        data.append('order_dir', this.options.sortDesc ? 'desc' : 'asc')
      }

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getLcdList.method,
          dicamoApi.requests.getLcdList.url,
          data
        )
        .then((res) => {
          this.lcdlist = []
          const data = res.data.data
          // if (this.type === 'noscheduled') {
          //   data.forEach(item => {
          //     if (item.playlist_set === '') this.lcdlist.push(item)
          //   })
          // } else if (this.type === 'scheduled') {
          //   data.forEach(item => {
          //     if (item.playlist_set !== '') this.lcdlist.push(item)
          //   })
          // } else {
          this.getLcdData(data)
          // this.lcdlist = data
          // }
        })
        .catch((error) => {
          this.lcdlist = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any playlist. error: ${error}`)
        })
    },
    rebootLcdItem () {
      const idxLcds = []
      let assign = []
      this.selected.forEach((item) => {
        idxLcds.push(item.idx_lcd)
      })
      assign = {
        company: this.$store.state.auth.user.company,
        store: this.$store.state.dataStore.selectedStore.code,
        lcds: idxLcds,
        cmd: 'reboot',
        timezone: this.$store.state.dataStore.timezone
      }

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.commandLcd.method,
          dicamoApi.requests.commandLcd.url,
          assign
        )
        .then((res) => {
          // alert('완료')
          this.isLoading = false
          EventBus.$emit('messageAlert', this.$t('SUCCESS'))
          this.lcdRebootDialog = false
        })
        .catch((error) => {
          this.isLoading = false
          console.debug(`error: ${error}`)
        })
    },
    resetLcdItem () {
      const idxLcds = []
      let assign = []
      this.selected.forEach((item) => {
        idxLcds.push(item.idx_lcd)
      })
      assign = {
        company: this.$store.state.auth.user.company,
        store: this.$store.state.dataStore.selectedStore.code,
        lcds: idxLcds,
        cmd: 'reset',
        timezone: this.$store.state.dataStore.timezone
      }

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.commandLcd.method,
          dicamoApi.requests.commandLcd.url,
          assign
        )
        .then((res) => {
          // alert('완료')
          this.isLoading = false
          EventBus.$emit('messageAlert', this.$t('SUCCESS'))
          this.lcdResetDialog = false
        })
        .catch((error) => {
          this.isLoading = false
          console.debug(`error: ${error}`)
        })
    },
    deleteLcd () {
      const data = new FormData()
      const idxLcds = []

      this.deleteDialog = false

      this.selected.forEach((item) => {
        idxLcds.push(item.idx_lcd)
      })

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('idx_lcds', JSON.stringify(idxLcds))
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.deleteLcdList.method,
          dicamoApi.requests.deleteLcdList.url,
          data
        )
        .then((res) => {
          // this.playlist = res.data.data
          EventBus.$emit('messageAlert', this.$t('SUCCESS'))
          this.resetLcd()
          // this.getPlaylistData(res.data.data)
        })
        .catch((error) => {
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any playlist. error: ${error}`)
        })
    },
    exportTable (arr) {
      const list = []
      arr.forEach((item) => {
        list.push({
          nickname: item.nickname,
          mac: item.mac,
          model: item.device_type,
          playlist_group: item.playlist_set_title,
          access: item.access
        })
      })
      return list
    },
    // 해당 부분은 Export 할 API를 구성한 후 수정 예정
    exportLcdList () {
      this.getExportLcdList()
    },
    paging (page) {
      this.page = page
      this.getLcdList()
    }
  },
  watch: {
    options: {
      handler () {
        this.getLcdList()
        this.page = 1
      },
      deep: true
    },
    store: {
      handler () {
        this.getLcdList()
        this.page = 1
        this.selected = []
      }
    },
    page: {
      handler () {
        this.getLcdList()
        this.selected = []
      }
    }
  }
}
</script>

<style scoped>
.pageInfo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
  font-size: var(--font18);
  color: #797979;
}
.video-preview .v-card__title {
  height: 53px;
  line-height: 24px;
}
::v-deep .calendar-col {
  width: 100%;
}
::v-deep .v-menu {
  margin-top: 35px;
}
::v-deep .v-input input:focus::placeholder {
  color: #fff;
}
::v-deep tr > th:first-child {
  text-align: center !important;
}
::v-deep tr > td:first-child {
  text-align: center !important;
}
::v-deep .popup-success .ico-success {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px !important;
  left: 50%;
  width: 58px;
  height: 58px;
  background: #001e38;
  border: 2px solid #fff;
  border-radius: 50% !important;
  transform: translate(-50%, -50%);
}

</style>
