<template>
  <v-dialog ref="dialog" v-model="showLCDDetailView" width="1080" scrollable persistent>
    <v-card class="popup">
      <v-card-title><h3 class="page-title-bar"><i class="ico ico-lcd-list"></i>{{$t('LCD Detail')}}</h3></v-card-title>
      <v-card-text class="pa-0">
        <v-layout column>
          <v-row no-gutters>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('Nickname')}}</dt>
                <dd>{{ lcdItem.nickname }}</dd>
              </dl>
            </v-col>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('Model')}}</dt>
                <dd>{{ getModel(lcdItem.device_type) }}</dd>
              </dl>
            </v-col>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('MAC Address')}}</dt>
                <dd>{{ lcdItem.mac }}</dd>
              </dl>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row no-gutters>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('IP')}}</dt>
                <dd>{{ lcdItem.ip }}</dd>
              </dl>
            </v-col>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('SSID')}}</dt>
                <dd>{{ lcdItem.ssid }}</dd>
              </dl>
            </v-col>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('Last Connection')}}</dt>
                <dd>{{ getTimezone(lcdItem) }}</dd>
              </dl>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row no-gutters>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('Launcher Version')}}</dt>
                <dd>{{ lcdItem.launcher_ver }}</dd>
              </dl>
            </v-col>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('Player Version')}}</dt>
                <!-- <dd>{{ getVersion(lcdItem.player_ver, 'player')}}</dd> -->
                <!--
                  ======================================================================================
                  App 버전이 업데이트 되면서 player_ver 변수에 service 버전이 같이 올라오던 현상 수정되었음.
                  App 버전 업데이트할 때 해당 코드 같이 업데이트해야함.
                  ======================================================================================
                 -->
                <dd> {{ lcdItem.player_ver }} </dd>
                <!-- <dd>{{ lcdItem.player_ver }}</dd> -->
              </dl>
            </v-col>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('Service Version')}}</dt>
                <!-- <dd>{{ getVersion(lcdItem.player_ver, 'service') }}</dd> -->
                <dd> {{ lcdItem.service_ver }} </dd>
                <!-- <dd>{{ lcdItem.player_ver }}</dd> -->
              </dl>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row no-gutters>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('CPU Usage')}}</dt>
                <dd>{{ Math.round(lcdItem.cpu) + '%' }}</dd>
              </dl>
            </v-col>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('Memory Usage')}}</dt>
                <dd>{{ Math.round(lcdItem.memory) + '%' }}</dd>
              </dl>
            </v-col>
            <v-col cols="4">
              <dl class="playlist-detail-info">
                <dt>{{$t('Storage Usage')}}</dt>
                <dd>{{ Math.round(lcdItem.storage) + '%' }}</dd>
              </dl>
            </v-col>
          </v-row>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            hide-slider
            center-active
            active-class="activeTab"
            class="mt-5"
          >
            <v-tab href="#tab-1st" class="primary--text">
              <span>{{ $t("PLAYLIST GROUP") }}</span>
            </v-tab>
            <v-tab href="#tab-2nd" class="primary--text tab-divider">
              <span>{{ $t("TEMPLATE AND PRODUCT") }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="tab-type">
            <v-tab-item
                :value="'tab-1st'"
                :transition="false"
                :reverse-transition="false"
                v-if="tab === 'tab-1st'"
              >
                <v-card flat>
                  <v-card-text class="mt-5" style="background: #FAFAFA; padding: 0px;">
                    <v-layout column class="mt-2">
                      <v-row no-gutters>
                        <v-col cols="8">
                          <dl class="playlist-detail-info">
                            <dt>{{$t('Mapped Playlist Group')}}</dt>
                            <dd>{{ lcdItem.playlist_set_title }}</dd>
                          </dl>
                        </v-col>
                        <v-col cols="2">
                          <dl class="playlist-detail-info">
                            <dt>{{$t('Category')}}</dt>
                            <dd style="max-width: 90px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ lcdItem.playlist_set_category }}</dd>
                          </dl>
                        </v-col>
                        <v-col cols="2">
                          <dl class="playlist-detail-info">
                            <dt>{{$t('Time')}}</dt>
                            <dd>{{ timeText(playlist.start_time, playlist.end_time) }}</dd>
                          </dl>
                        </v-col>
                      </v-row>
                    </v-layout>
                    <v-layout column class="mt-2">
                      <v-row no-gutters>
                        <v-col cols="5" class="playlist-group-time">
                          <div class="d-flex align-center">
                            <div class="mr-8">
                              <v-slider v-model="timeSlider" vertical class="playslider-vertical">
                                <template v-slot:append>
                                  <v-btn icon small @click="decrement"><img src="@/assets/img/ico-play-up.png"></v-btn>
                                </template>
                                <template v-slot:prepend>
                                  <v-btn icon @click="increment"><img src="@/assets/img/ico-play-down.png"></v-btn>
                                </template>
                              </v-slider>
                            </div>
                            <div class="playlist-group-timeline">
                              <perfect-scrollbar style="height:288px;padding-right: 16px;" ref="ps">
                                <div class="playlist-timeline short-timeline">
                                  <ul>
                                    <li v-for="time in 24" :key="time.index" :style="time < 24 ? { height: time_line_height + 'px', position: 'relative' } : { height: 'initial' }">
                                      <div v-show="timeSlider > 50 || minute === 1" v-for="minute in 6" :key="minute.index" :style="{ height: time_line_height / 6 + 'px', width: '100%' }"> {{ getTimeText(time, minute) }} </div>
                                      <!-- <li v-for="minute in 5" :key="minute.index" :style=" { height: time_line_height / 5 + 'px' } "><span> {{ minute }} </span></li> -->
                                    </li>
                                  </ul>
                                </div>
                                <div class="playlist-events">
                                  <ul>
                                    <li v-for="(item, index) in events" :key="index" class="playlist-event" :style="{ top: (time_line_height / 60 ) * item.startTime + 14 + 'px', height: (time_line_height / 60) * (item.endTime - item.startTime) + 'px' }">
                                    <a href="" @click.prevent="onClickItem(index)" :class="item.active ? 'active' : ''">
                                      <em class="playlist-event-name"> {{ item.text }} </em>
                                    </a>
                                    </li>
                                  </ul>
                                </div>
                              </perfect-scrollbar>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="7" style="margin-left: 3%;max-width: calc(58.3333333333% - 3%);">
                          <div class="playlist-group-preview">
                            <v-card flat class="video-preview">
                              <v-card-title>{{$t('PREVIEW')}}</v-card-title>
                              <v-card-text class="text-center pb-10 pt-9">
                                <div class="px-6">

                                  <!-- <div class="img-preview"><img src="@/assets/img/img-playlist-group-preview.png" alt="" style="width:100%;" /></div> -->

                                  <div class="d-flex flex-row">
                                    <div class="d-flex" v-for="(item, index) in contents.division" :key="index" :style=" { width: item+'%' }">
                                      <v-carousel
                                        tag="div"
                                        cycle
                                        interval="2500"
                                        hide-delimiter-background
                                        hide-delimiters
                                        :height="lcdItem.device_type === '23' ? 39.5 : 135"
                                        :show-arrows="false"
                                      >
                                        <v-carousel-item
                                        v-for="(item, index) in JSON.parse(contents[index][0].still_shot)"
                                        :key="index"
                                        :src="srcURL + item"
                                        reverse-transition="fade-transition"
                                        transition="fade-transition">
                                        </v-carousel-item>
                                      </v-carousel>
                                    </div>
                                  </div>
                                  <h6 class="caption text-left pt-6 pb-2">{{$t('Contents List')}}</h6>
                                  <perfect-scrollbar style="height:112px">
                                    <!-- <div class="simple-line-table">
                                      <v-simple-table>
                                        <template v-slot:default>
                                          <tbody>
                                            <tr >
                                              <td>{{  }}</td>
                                            </tr>
                                          </tbody>
                                        </template>
                                      </v-simple-table>
                                    </div> -->
                                    <div class="d-flex flex-column line-table" v-if="isContents">
                                      <div v-for="(items, i) in totalItem" :key="i" class="d-flex flex-row line-table-row">
                                        <div v-for="(item, index) in contents.division" :key="index" class="d-flex line-table-item" :style=" { width: item+'%' }">
                                          <span class="width-full">{{ contents[index][i] ? contents[index][i].media_name : '' }}</span>
                                          <!-- {{ contents[index].length !== 0 ? contents[index][i].media_name : '' }} -->
                                        </div>
                                      </div>
                                    </div>
                                  </perfect-scrollbar>
                                </div>
                              </v-card-text>
                          </v-card>
                          </div>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn text icon @click="editPlaylistGroup" class="btn">{{$t('Edit Playlist Group')}}</v-btn>
                    <v-btn text icon @click="closeDetail" class="btn">{{$t('Close')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :value="'tab-2nd'"
                :transition="false"
                :reverse-transition="false"
                v-if="tab === 'tab-2nd'"
              >
                <v-card flat>
                  <v-card flat>
                    <v-card-text>
                      <v-layout column class="mt-2">
                        <v-row no-gutters>
                          <v-col cols="5">
                            <h4 class="mb-4">{{$t('Assigned Product List')}}</h4>
                            <perfect-scrollbar style="height:330px">
                              <div class="tbl-type06">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th> </th>
                                        <th class="text-left">{{$t('DESCRIPTION')}}</th>
                                        <th class="text-left">{{$t('PRODUCT ID')}}</th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="articleData.length > 0">
                                      <tr v-for="(item, index) in articleData" :key="index">
                                        <td> <v-checkbox v-model="visibleList" :value="index"> </v-checkbox> </td>
                                        <td> <div :style="{ 'max-width': '130px', 'text-overflow': 'ellipsis', overflow: 'hidden', 'white-space': 'nowrap' }"> {{ item.article_info.article.articleName }} </div> </td>
                                        <td> <div :style="{ 'max-width': '130px', 'text-overflow': 'ellipsis', overflow: 'hidden', 'white-space': 'nowrap' }"> {{ item.article_info.article.articleId }} </div> </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td colspan="3" style="text-align: center;">{{$t('No data available')}}</td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </div>
                            </perfect-scrollbar>
                          </v-col>
                          <v-col cols="7" style="margin-left: 3%;max-width: calc(58.3333333333% - 3%);">
                            <h4 class="mb-5">{{$t("Selected Product Detail")}}</h4>
                            <dl class="selected-info mb-5">
                              <dt>{{$t('Template Name')}}</dt> <span v-if="articleData.length > 0">{{ articleData[templateIndex].title }}</span>
                            </dl>
                            <div class="selected-box mb-3" style="overflow: hidden;">
                              <perfect-scrollbar style="width: 450px; height: 200px;">
                                <canvas id="canvas" :width="canvas.width" :height="canvas.height"></canvas>
                              <!-- <div v-if="articleData.length > 0" style="width: 200px; height: 200px;" :style="{ 'background-image': 'url(' + getThumbnail(articleData[templateIndex].template_img) + ')', 'background-size': 'contain' }" /> -->
                              <!-- <div v-if="articleData.length > 0" :style="{ 'background-image': 'url(' + templateURL + articleData[templateIndex].template_img + ')', 'background-size': 'contain' }" /> -->

                                <!-- <div v-if="articleData.length > 0" class="d-flex" :style="{ 'justify-content': 'center', overflow: 'hidden', width: articleData[templateIndex].width_px + 'px', height: articleData[templateIndex].height_px + 'px' }">
                                  <div style="position: relative">
                                    <div :style="getStyle(item)"
                                      v-for="(item, index) in templateData.bind" :key="index"
                                      >
                                        {{ item.column !== 'BARCODE' ? getArticle(item.column) : '' }}
                                        <barcode class="barcode" :options="{ displayValue: false }" v-if="item.column === 'BARCODE'" v-bind:value="getArticle(item.column)" tag="img" />
                                    </div>
                                    <img :src="templateURL + articleData[templateIndex].template_img" crossorigin="anonymous" @load="isLoading = false" />
                                  </div>
                                </div> -->
                              </perfect-scrollbar>
                              <!-- <img :style="{ width: previewWidth + 'px', height: previewHeight + 'px' }" v-if="articleData.length > 0" :src="templateURL + articleData[templateIndex].template_img"> -->
                            </div>
                            <div class="time-slider" :style="{ overflow: 'hidden' }">
                              <div class="d-flex align-center">
                                <div v-for="(item, index) in articleData" :key="index" @click="selectTemplateProduct(index)" :class="index === templateIndex ? 'timecell-event selected' : 'timecell-event'" :style="getTemplateStyle(item, index)">
                                  <!-- :style="{ width: previewWidth(item.width_px) + 'px', height: previewHeight(item.height_px) + 'px' }" -->
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-layout>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                      <!-- <v-btn text icon @click="editAssign" class="btn">{{$t('Edit Assign info')}}</v-btn> -->
                      <assign-dialog
                        @fireResetLcd="resetLcd"
                        :lcdData="assignLcdItem"
                        :mode="'edit'"
                        :btype="'edit'"
                        :user="user"
                      />
                      <v-btn text icon @click="closeDetail" class="btn">{{$t('Close')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-card>
              </v-tab-item>
          </v-tabs-items>

          <!-- <v-tabs-items v-model="tab" class="tab-type">
            <v-tab-item
              :value="'tab-1st'"
              :transition="false"
              :reverse-transition="false"
              v-if="tab === 'tab-1st'"
              >
              <v-layout column class="mt-2">
                <v-row no-gutters>
                  <v-col cols="8">
                    <dl class="playlist-detail-info">
                      <dt>Mapped Playlist Group</dt>
                      <dd>{{ lcdItem.playlist_set_title }}</dd>
                    </dl>
                  </v-col>
                  <v-col cols="2">
                    <dl class="playlist-detail-info">
                      <dt>Category</dt>
                      <dd>{{ lcdItem.playlist_set_category }}</dd>
                    </dl>
                  </v-col>
                  <v-col cols="2">
                    <dl class="playlist-detail-info">
                      <dt>Time </dt>
                      <dd>{{ timeText(playlist.start_time, playlist.end_time) }}</dd>
                    </dl>
                  </v-col>
                </v-row>
              </v-layout>
            </v-tab-item>
            <v-tab-item
              :value="'tab-2nd'"
              :transition="false"
              :reverse-transition="false"
              v-if="tab === 'tab-2nd'"
              >
              <v-layout column class="mt-4">
                <v-row no-gutters>
                  <v-col cols="6">
                    <dl class="playlist-detail-info">
                      <dt>Mapped Template</dt>
                      <dd> {{ }}</dd>
                    </dl>
                  </v-col>
                  <v-col cols="2">
                    <dl class="playlist-detail-info">
                      <dt>Category</dt>
                      <dd> {{ }} </dd>
                    </dl>
                  </v-col>
                  <v-col cols="4">
                    <dl class="playlist-detail-info">
                      <dt>Product List</dt>
                      <dd>{{ }}</dd>
                    </dl>
                  </v-col>
                </v-row>
              </v-layout>
            </v-tab-item>
          </v-tabs-items> -->
        </v-layout>
      </v-card-text>
      <!-- <v-card-actions class="d-flex justify-center">
        <v-btn text icon @click="closeDetail" class="btn">{{$t('Close')}}</v-btn>
      </v-card-actions> -->
    </v-card>
    <v-dialog v-model="isLoading" persistent>
      <v-progress-circular
        style="width:100%; overflow: hidden;"
        indeterminate
        :size="50"
        :width="7"
        color="#ddd"
      ></v-progress-circular>
    </v-dialog>
    <v-dialog v-model="fixPreviewDialog" width="300" @click:outside="fixPreviewClose">
      <v-img :src="fixPreviewSrc" @click="fixPreviewClose"> </v-img>
      <div style="position: relative" class="d-flex" :style="{ 'background-image': fixPreviewSrc, 'line-height': 1.5, 'justify-content': 'center'}" />
    </v-dialog>
  </v-dialog>
</template>
<script>
import EventBus from '@/plugins/eventBus'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import commons from '@/plugins/commons'
import dicamoApi from '@/lcd/plugins/dicamoApi'
import AssignDialog from './AssignDialog.vue'
// import VueBarcode from '@xkeshi/vue-barcode'
import codes from '@/plugins/codes'
import Axios from 'axios'
// import configs from '@/plugins/configs'
export default {
  props: {
    user: Object
  },
  components: {
    PerfectScrollbar,
    AssignDialog
    // barcode: VueBarcode
  },
  mounted () {
    EventBus.$on('showLcdDetail', (item) => {
      this.showLCDDetailView = true
      // this.idx_lcd = item.idx_lcd
      this.getLcdItem(item.idx_lcd)
      this.getLcdDetail(item.idx_lcd)
      this.getTemplateMappingList()
      this.idx_lcd = item.idx_lcd
      // this.lcdItem = item
      // this.lcdItem = item
      // if (this.isContents) this.events[this.selectEventIndex].active = 'active'
      if (this.events.length > 0) {
        this.events.forEach(item => {
          item.active = null
        })
        this.events[0].active = 'active'
      }
    })
  },
  data () {
    return {
      idx_lcd: 0,
      srcURL: dicamoApi.requests.thumbnail.url,
      templateURL: dicamoApi.requests.labelThumbnail.url,
      isContents: false,
      showLCDDetailView: false,
      tab: '',
      selectEventIndex: 0,
      videoPlay: 0,
      timeSlider: 0,
      playlist: [],
      events: [],
      lcdItem: [],
      articleData: [],
      templateIndex: 0,
      isLoading: false,
      articleList: [],
      visibleList: [],
      fixPreviewDialog: '',
      fixPreviewSrc: '',
      templateMappingList: [],
      canvas: {
        width: 0,
        height: 0
      }
    }
  },
  methods: {
    // changeList () {
    // },
    fixPreviewClose () {
      this.fixPreviewDialog = false
      this.fixPreviewSrc = ''
    },
    changePreview (template, tIndex = -1) {
      this.isLoading = true
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      let text = ''

      const img = new Image()

      this.canvas.width = template.width
      this.canvas.height = template.height
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      img.addEventListener('load', () => {
        ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height)
        ctx.lineWidth = 0
        const articleList = template.bind

        articleList.forEach((item, index) => {
          if (item.column !== 'barcode') {
            text = this.getArticle(item.column)
            if (!text) return
            // ctx = canvas.getContext('2d')
            let maxLine = item.line === 0 ? 999 : item.line
            if (!maxLine) maxLine = 999
            // const align = item.align === 'left' ? 'start' : item.align === 'right' ? 'end' : 'center'
            ctx.font = `${item.fontSize}px ${item.fontFamily}`
            ctx.strokeStyle = item.stroke
            if (item.y < item.fontSize) {
              ctx.textBaseline = 'bottom'
            } else {
              ctx.textBaseline = 'top'
            }
            ctx.lineWidth = item.strokeWidth
            ctx.width = item.width
            ctx.height = item.height
            ctx.textAlign = item.align
            ctx.fillStyle = item.fill
            let offset = 0
            if (item.align === 'left') offset = 0
            else if (item.align === 'center') offset = item.width / 2
            else if (item.align === 'right') offset = item.width
            if (!item.wrap) item.wrap = 'word'

            // console.log(`align : ${item.align} offset: ${offset} x: ${item.x}`)

            if (!item.fit) {
              let line = ''
              let lineHeight = 0
              let count = 0
              let testLine = ''
              let metrics = ''
              let testWidth = 0
              const maxWidth = item.width
              if (item.wrap === 'word') {
                const words = String(text).split(' ')

                for (let n = 0; n < words.length; n++) {
                  testLine = line + words[n]
                  metrics = ctx.measureText(testLine)
                  testWidth = metrics.width
                  if (testWidth > maxWidth && n > 0 && item.height > lineHeight) {
                    if (maxLine > count) {
                      if (item.strokeWidth > 0) ctx.strokeText(line, item.x + offset, item.y + lineHeight)
                      ctx.fillText(line, item.x + offset, item.y + lineHeight)
                    }
                    line = words[n] + ' '
                    lineHeight += item.fontSize
                    count++
                  } else {
                    line = testLine + ' '
                  }
                }
                if (line.length > 0 && maxLine > count) {
                  if (item.strokeWidth > 0) ctx.strokeText(line, item.x + offset, item.y + lineHeight, item.width)
                  ctx.fillText(line, item.x + offset, item.y + lineHeight, item.width)
                }
              } else {
                // const words = String(text)
                for (let i = 0; i < text.length; i++) {
                  testLine = line + text[i]
                  metrics = ctx.measureText(testLine)
                  testWidth = metrics.width
                  if (testWidth > maxWidth && i > 0 && item.height > lineHeight) {
                    if (maxLine > count) {
                      if (item.strokeWidth > 0) ctx.strokeText(testLine, item.x + offset, item.y + lineHeight)
                      ctx.fillText(testLine, item.x + offset, item.y + lineHeight)
                    }
                    line = text[i] + ''
                    lineHeight += item.fontSize
                    count++
                  } else {
                    line = testLine + ''
                  }
                }
                if (line.length > 0 && maxLine > count) {
                  if (item.strokeWidth > 0) ctx.strokeText(testLine, item.x + offset, item.y + lineHeight)
                  ctx.fillText(testLine, item.x + offset, item.y + lineHeight)
                }
              }
            } else {
              if (item.strokeWidth > 0) ctx.strokeText(text, item.x, item.y + item.fontSize, item.width)
              ctx.fillText(text, item.x, item.y + item.fontSize, item.width)
            }
          }
        })
        this.isLoading = false
      })

      let indexTemplate = tIndex < 0 ? this.articleData[this.templateIndex].idx_template : tIndex

      let ext = String(indexTemplate).split('.')
      ext = ext[ext.length - 1]

      if (ext !== 'png') indexTemplate += '.png'

      const templatePreview = this.templateURL + indexTemplate + '?t=' + Math.round(new Date().getTime() / 1000)

      img.src = templatePreview
      // img.crossOrigin = 'Anonymous'
    },
    getTemplateMappingList () {
      const data = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.$store.state.dataStore.selectedStore.code
        }
      }
      Axios.get(dicamoApi.requests.getTemplateMapping.url, data)
        .then(res => {
          const list = res.data.data
          const tableList = []
          list.forEach(item => {
            tableList.push(item)
          })
          this.templateMappingList = tableList
        })
        .catch(error => {
          console.log(error)
          console.debug(`error: ${error}`)
        })
      // this.$utils
      //   .callAxios(
      //     dicamoApi.requests.getTemplateMapping.method,
      //     dicamoApi.requests.getTemplateMapping.url,
      //     data
      //   )
      //   .then(res => {
      //     this.templateMappingList = res.data.data
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     console.debug(`error: ${error}`)
      //   })
    },
    getTemplateStyle (item, index) {
      const ratio = 510 / 1920
      let visible = true
      this.visibleList.forEach(idx => {
        if (index === idx) visible = false
      })
      return {
        position: 'absolute',
        top: '0px',
        width: Number(item.width_px) * ratio + 'px',
        // height: Number(item.height_px) * ratio + 'px',
        left: Number(item.xposition) * ratio + 'px',
        display: visible ? 'none' : 'block'
      }
    },
    getAnArticleData (articleId, index) {
      const params = {
        store: this.$store.state.dataStore.selectedStore.code,
        company: this.$store.state.auth.user.company,
        article: articleId
      }
      const config = { params }

      return this.$utils
        .callAxios(
          codes.requests.getAnArticle.method,
          codes.requests.getAnArticle.url,
          config
        )
        .then(res => {
          this.articleData[index].article_info.article = res.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getArticle (article) {
      return this.selectArticle[article]
    },
    getThumbnail (filename) {
      const thumb = filename.split('.')
      return this.templateURL + thumb[0] + '_thumb.' + thumb[1]
    },
    getModel (item) {
      if (item === '23') return 'Newton Touch 23"'
      else if (item === '29') return 'Newton Touch 29"'
      else if (item === 'FHD') return 'FHD Box'
      else if (item === 'tablet') return 'Tablet'
      else return ''
    },
    getTimezone (lcd) {
      if (!lcd.last_nop_time) return ''

      if (lcd.last_nop_time === '0000-00-00 00:00:00') return ''
      if (String(lcd.last_nop_time).indexOf('1970-01-01') > -1) return ''

      // if (configs.Tz_Enable) {
      const date = new Date(lcd.last_nop_time)
      return commons.convertDate(date)
      // } else {
      // return lcd.last_nop_time.substring(0, lcd.last_nop_time.indexOf('.'))
      // }

      // let dateText = commons.convertDate(date)
      // dateText = dateText.split('-')

      // return dateText[0] + dateText[1] + dateText[2]
    },
    previewWidth (item) {
      // return this.articleData[0].width_px * this.deviceSize.width
      return item * this.deviceSize.width
    },
    previewHeight (item) {
      // return this.articleData[0].height_px * this.deviceSize.height
      return item * this.deviceSize.height
    },
    resetLcd () {
      // this.showLCDDetailView = true
      // this.idx_lcd = item.idx_lcd
      this.articleData = []
      this.getLcdItem(this.idx_lcd)
      this.getLcdDetail(this.idx_lcd)
      this.getTemplateMappingList()
      // this.lcdItem = item
      // this.lcdItem = item
      // if (this.isContents) this.events[this.selectEventIndex].active = 'active'
      if (this.events.length > 0) {
        this.events.forEach(item => {
          item.active = null
        })
        this.events[0].active = 'active'
      }
      // this.getLcdItem(this.idx_lcd)
      // this.getLcdDetail(this.idx_lcd)
      // this.selectTemplateProduct()
      // if (this.isContents) this.events[this.selectEventIndex].active = 'active'
    },
    getVersion (version, type) {
      if (version) {
        if (version) {
          const text = version.split('_')
          const versionText = {
            service: '',
            player: ''
          }

          if (text[0][0] === 'S') {
            if (text.length < 2) {
              versionText.service = text[0]
              versionText.player = ''
            } else {
              // versionText.service = text[1].slice(0, text[2].length - 3)
              versionText.service = text[0]
              versionText.player = text[text.length - 1]
            }
          } else if (text[0][0] === 'P') {
            if (text.length < 2) {
              versionText.player = text[0]
            } else {
              // versionText.player = text[1].slice(0, text[2].length - 3)
              versionText.player = text[0]
              versionText.service = text[text.length - 1]
            }
          }

          if (type === 'player') {
            return versionText.player
          } else {
            return versionText.service
          }
        }
      }
    },
    getStyle (item) {
      const style = {
        position: 'absolute',
        width: item.width + 'px',
        height: item.height + 'px',
        top: item.y + 'px',
        left: item.x + 'px',
        'text-align': item.align,
        color: item.fill,
        'font-family': item.fontFamily,
        'font-size': item.fontSize + 'px',
        'font-weight': item.fontStyle,
        'font-style': item.fontStyle,
        'text-stroke-width': '1px',
        'text-stroke-color': item.stroke,
        // 'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
        'line-height': 1.5
      }
      return style
    },
    editPlaylistGroup () {
      const index = Number(this.lcdItem.idx_playlist_set)
      this.$router.push({ name: 'Dicamo Edit Playlist Group', params: { index: index } })
    },
    selectTemplateProduct (index) {
      this.isLoading = true
      this.templateIndex = index
      if (this.templateData) {
        this.changePreview(this.templateData)
      }
    },
    getLcdDetail (index) {
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('idx_lcd', index)
      data.append('type', 'item')
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getLcdList.method,
          dicamoApi.requests.getLcdList.url,
          data
        )
        .then(res => {
          this.lcdItem = res.data.data
          this.getPlaylistData()
          // this.lcdlist = res.data.data
        })
        .catch(error => {
          this.lcdItem = []
          console.debug(`Could not find any template. error: ${error}`)
        })
    },
    getLcdItem (index) {
      this.isLoading = true
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('idx_lcd', index)
      data.append('type', 'stripe')
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getLcdTemplateAndProduct.method,
          dicamoApi.requests.getLcdTemplateAndProduct.url,
          data
        )
        .then(res => {
          //  2022-01-06 수정
          //  서버에서 update된 article 정보를 받아올 수 없기 때문에 solum에 api를 던져서 데이터를 채워넣어야함
          const list = res.data.data

          list.forEach((item, index) => {
            if (item.product_id === 'DICAMO_FIX_TEMPLATE') {
              this.fixTemplate = item
              const templatePreview = `${this.templateURL}${item.idx_template}.png`
              this.fixPreviewSrc = templatePreview + '?t=' + new Date().getTime()
            } else {
              this.articleData.push(item)

              let isMapping = false
              let templateIndex = 0
              const article = item.article_info.article.data

              this.templateMappingList.forEach(temp => {
                if (article[temp.target_field] === temp.expected_value) {
                  templateIndex = temp.idx_template
                  isMapping = true
                }
              })

              if (isMapping) {
                const data = new FormData()
                data.append('company', this.$store.state.auth.user.company)
                data.append('store', this.$store.state.dataStore.selectedStore.code)
                data.append('type', 'item')
                data.append('idx_template', templateIndex)

                this.$utils
                  .callAxiosWithBody(
                    dicamoApi.requests.getTemplateList.method,
                    dicamoApi.requests.getTemplateList.url,
                    data
                  )
                  .then(res => {
                    const request = res.data.data
                    this.articleData[index].title = request.title
                    const template = JSON.parse(request.template_data)
                    this.articleData[index].width_px = template.width
                    this.articleData[index].template_data = request.template_data
                    this.articleData[index].template_img = templateIndex + '.png'
                    this.articleData[index].idx_template = templateIndex
                    // this.changePreview(JSON.parse(request.template_data), templateIndex)

                    // const rawData = JSON.parse(request.raw_data)
                  })
                  .catch(error => {
                    console.debug(error)
                  //   this.lcdlist = []
                  })
              }
            }
          })
          // 주석처리
          // this.articleData = res.data.data

          // eslint-disable-next-line
          this.articleData.forEach((item, index) => {
            this.visibleList.push(index)
          })
          this.isLoading = false
          // this.articleData.forEach((item, index) => {
          //   this.getAnArticleData(item.article_info.article.articleId, index)
          // })

          // this.lcdlist = res.data.data
        })
        .catch(error => {
          this.lcdlist = []
          console.debug(`Could not find any template. error: ${error}`)
        })
    },
    timeText (startTime, endTime) {
      let text = ''
      if (commons.notEmpty(startTime) && commons.notEmpty(endTime)) {
        text = startTime + ' ~ ' + endTime
      } else {
        text = ''
      }
      return text
    },
    getTimeText (hour, minute) {
      const time = ((hour - 1) + '').padStart(2, '0') + ':' + ((minute - 1) + '').padEnd(2, '0')
      return time
    },
    getPlaylistData () {
      const json = this.lcdItem.playlist_set === '' ? '' : JSON.parse(this.lcdItem.playlist_set)
      if (json.length === 0 || json === '') {
        this.isContents = false
      } else {
        this.isContents = true
        // const json = JSON.parse(this.lcdItem.playlist_set)
        this.events = json
        this.events.forEach(item => {
          item.active = ''
        })
        this.events[0].active = 'active'
        this.playlist = {
          start_time: this.getTime(json.length > 0 ? json[0].startTime : ''),
          // end_time: Number(this.getTime(json.length > 0 ? json[json.length - 1].endTime : '')),
          end_time: this.getTime(json.length > 0 ? this.getEndTime(json) : ''),
          running_time: this.getTime(json.length > 0 ? json[json.length - 1].endTime - json[0].startTime : '')
        }
      }
    },
    getEndTime (array) {
      const times = []
      array.forEach(item => {
        times.push(item.endTime)
      })
      return Math.max(...times)
    },
    getTime (minute) {
      const cal = Number(minute) / 60

      const time = ((Math.floor(cal) + '').padStart(2, '0') + ':' + (minute % 60 + '').padStart(2, '0'))
      return time
    },
    onClickItem (index) {
      this.events.forEach(item => {
        item.active = false
      })
      this.selectEventIndex = index
      this.events[this.selectEventIndex].active = true
    },
    closeDetail () {
      this.init()
      this.showLCDDetailView = false
    },
    init () {
      this.isContents = false
      document.getElementsByClassName('v-dialog')[0].scrollTop = 0
      if (this.tab === 'tab-1st') this.$refs.ps.$el.scrollTop = 0
      // this.events.forEach(item => {
      //   item.active = false
      // })
      this.events = []
      this.visibleList = []
      this.selectEventIndex = 0
      this.templateIndex = 0
      this.lcdItem = []
      this.playlist = []
      this.tab = 'tab-1st'
      this.articleData = []
      this.timeSlider = 0
      this.$emit('fireResetLcd')
    },
    increment () {
      this.timeSlider -= 10
    },
    decrement () {
      this.timeSlider += 10
    }
  },
  computed: {
    selectArticle () {
      if (this.articleData[this.templateIndex]) {
        return this.articleData[this.templateIndex].article_info.article.data
      } else return null
    },
    templateData () {
      if (this.articleData[this.templateIndex]) {
        return JSON.parse(this.articleData[this.templateIndex].template_data)
      } else return null
      // return JSON.parse(this.articleData[this.templateIndex].template_data)
    },
    time_line_height () {
      return 30 + (this.timeSlider * 2.4)
    },
    contents () {
      return this.isContents ? this.events[this.selectEventIndex].division : []
    },
    totalItem () {
      if (this.isContents) {
        const array = []
        for (let i = 0; i < 4; i++) {
          array.push(this.contents[i].length)
        }
        return Math.max.apply(null, array)
      }
      return []
    },
    assignLcdItem () {
      const item = []
      if (this.lcdItem)item.push(this.lcdItem)
      return item
    },
    deviceSize () {
      return {
        width: 510 / 1920,
        height: 38 / 158
      }
    }
  },
  watch: {
    isLoading (val) {
      if (val) {
        setTimeout(() => { this.isLoading = false }, 500)
      }
    },
    tab (val) {
      if (val === 'tab-2nd') {
        this.$nextTick(() => {
          this.selectTemplateProduct(this.templateIndex)
          if (this.templateData) this.changePreview(this.templateData)
        })
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-image > div {
  background-size: 100% 100%;
}
.ps__rail-x {
  opacity: 0.6 !important;
}
.selected {
  background: #797979;
  z-index: 101 !important;
}
.pageInfo  {position:relative;display: flex;align-items: center;margin-bottom:8px}
.line-table {
  overflow: hidden;
  border-top: 1px solid #959595;
  border-right: 1px solid #959595;
}
.line-table-row {
  border-bottom: 1px solid #959595 !important;
  border-left: 1px solid #959595;
}
.line-table-item > span  {
  border-right: 1px solid #959595;
  font-size:13px;
  text-align: center;
  color: #000;
  height: 37px;
}
.line-table-row > div:last-child > span {
  border-right: none;
  font-size:13px;
  text-align: center;
  color: #000;
  height: 37px;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab.rounded-t-xl {border-top-left-radius: 24px;border-top-right-radius: 24px;}
.v-application .primary--text {
  color: var(--v-primary-base) !important;
  caret-color: #ffffff !important;
}
.v-tab:before {opacity: 0.12;border-radius: 8px 8px 0 0;}
.v-tab--active.v-tab:not(:focus)::before {
    opacity: 0;
    border-radius: 8px 8px 0 0;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab--active {
  border-right: 3px solid #ccd2d7;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
    border: none !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab {
  min-height: 0px !important;
  left: 0px !important;
  min-width: 160px !important;
  font-size: 15px;
  border:none;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
  border-top:1px solid #f9f9f9;
  border-left: 1px solid #ededed;
  background: #fff;
}
.theme--light.v-tabs-items {background-color: white !important;}
.tab-body {border-radius: 0 0 8px 8px !important;box-shadow: 3px 1px 6px -4px rgb(0 0 0 / 20%),  0px 2px 2px -1px rgb(0 0 0 / 14%) !important;border-left: 1px solid #ededed;margin-top: -2px;border-top: 1px solid #fbfbfb;}

::v-deep .barcode {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 180px !important;
  height: 20px !important;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
