var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      ref: "dialog",
      attrs: { width: "1080", scrollable: "", persistent: "" },
      model: {
        value: _vm.showLCDDetailView,
        callback: function($$v) {
          _vm.showLCDDetailView = $$v
        },
        expression: "showLCDDetailView"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-lcd-list" }),
              _vm._v(_vm._s(_vm.$t("LCD Detail")))
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Nickname")))]),
                          _c("dd", [_vm._v(_vm._s(_vm.lcdItem.nickname))])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Model")))]),
                          _c("dd", [
                            _vm._v(
                              _vm._s(_vm.getModel(_vm.lcdItem.device_type))
                            )
                          ])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("MAC Address")))]),
                          _c("dd", [_vm._v(_vm._s(_vm.lcdItem.mac))])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "my-2" }),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("IP")))]),
                          _c("dd", [_vm._v(_vm._s(_vm.lcdItem.ip))])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("SSID")))]),
                          _c("dd", [_vm._v(_vm._s(_vm.lcdItem.ssid))])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Last Connection")))]),
                          _c("dd", [
                            _vm._v(_vm._s(_vm.getTimezone(_vm.lcdItem)))
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "my-2" }),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [
                            _vm._v(_vm._s(_vm.$t("Launcher Version")))
                          ]),
                          _c("dd", [_vm._v(_vm._s(_vm.lcdItem.launcher_ver))])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Player Version")))]),
                          _c("dd", [
                            _vm._v(" " + _vm._s(_vm.lcdItem.player_ver) + " ")
                          ])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Service Version")))]),
                          _c("dd", [
                            _vm._v(" " + _vm._s(_vm.lcdItem.service_ver) + " ")
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "my-2" }),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("CPU Usage")))]),
                          _c("dd", [
                            _vm._v(_vm._s(Math.round(_vm.lcdItem.cpu) + "%"))
                          ])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Memory Usage")))]),
                          _c("dd", [
                            _vm._v(_vm._s(Math.round(_vm.lcdItem.memory) + "%"))
                          ])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("dl", { staticClass: "playlist-detail-info" }, [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Storage Usage")))]),
                          _c("dd", [
                            _vm._v(
                              _vm._s(Math.round(_vm.lcdItem.storage) + "%")
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-tabs",
                    {
                      staticClass: "mt-5",
                      attrs: {
                        "background-color": "transparent",
                        "hide-slider": "",
                        "center-active": "",
                        "active-class": "activeTab"
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          staticClass: "primary--text",
                          attrs: { href: "#tab-1st" }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("PLAYLIST GROUP")))])]
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "primary--text tab-divider",
                          attrs: { href: "#tab-2nd" }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("TEMPLATE AND PRODUCT")))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      staticClass: "tab-type",
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _vm.tab === "tab-1st"
                        ? _c(
                            "v-tab-item",
                            {
                              attrs: {
                                value: "tab-1st",
                                transition: false,
                                "reverse-transition": false
                              }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "mt-5",
                                      staticStyle: {
                                        background: "#FAFAFA",
                                        padding: "0px"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { column: "" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "8" } },
                                                [
                                                  _c(
                                                    "dl",
                                                    {
                                                      staticClass:
                                                        "playlist-detail-info"
                                                    },
                                                    [
                                                      _c("dt", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Mapped Playlist Group"
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _c("dd", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.lcdItem
                                                              .playlist_set_title
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [
                                                  _c(
                                                    "dl",
                                                    {
                                                      staticClass:
                                                        "playlist-detail-info"
                                                    },
                                                    [
                                                      _c("dt", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Category")
                                                          )
                                                        )
                                                      ]),
                                                      _c(
                                                        "dd",
                                                        {
                                                          staticStyle: {
                                                            "max-width": "90px",
                                                            overflow: "hidden",
                                                            "text-overflow":
                                                              "ellipsis",
                                                            "white-space":
                                                              "nowrap"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.lcdItem
                                                                .playlist_set_category
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [
                                                  _c(
                                                    "dl",
                                                    {
                                                      staticClass:
                                                        "playlist-detail-info"
                                                    },
                                                    [
                                                      _c("dt", [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("Time"))
                                                        )
                                                      ]),
                                                      _c("dd", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.timeText(
                                                              _vm.playlist
                                                                .start_time,
                                                              _vm.playlist
                                                                .end_time
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { column: "" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "playlist-group-time",
                                                  attrs: { cols: "5" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "mr-8" },
                                                        [
                                                          _c("v-slider", {
                                                            staticClass:
                                                              "playslider-vertical",
                                                            attrs: {
                                                              vertical: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "append",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              "",
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.decrement
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              attrs: {
                                                                                src: require("@/assets/img/ico-play-up.png")
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                },
                                                                {
                                                                  key:
                                                                    "prepend",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.increment
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              attrs: {
                                                                                src: require("@/assets/img/ico-play-down.png")
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              3494992487
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.timeSlider,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.timeSlider = $$v
                                                              },
                                                              expression:
                                                                "timeSlider"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "playlist-group-timeline"
                                                        },
                                                        [
                                                          _c(
                                                            "perfect-scrollbar",
                                                            {
                                                              ref: "ps",
                                                              staticStyle: {
                                                                height: "288px",
                                                                "padding-right":
                                                                  "16px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "playlist-timeline short-timeline"
                                                                },
                                                                [
                                                                  _c(
                                                                    "ul",
                                                                    _vm._l(
                                                                      24,
                                                                      function(
                                                                        time
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key:
                                                                              time.index,
                                                                            style:
                                                                              time <
                                                                              24
                                                                                ? {
                                                                                    height:
                                                                                      _vm.time_line_height +
                                                                                      "px",
                                                                                    position:
                                                                                      "relative"
                                                                                  }
                                                                                : {
                                                                                    height:
                                                                                      "initial"
                                                                                  }
                                                                          },
                                                                          _vm._l(
                                                                            6,
                                                                            function(
                                                                              minute
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  directives: [
                                                                                    {
                                                                                      name:
                                                                                        "show",
                                                                                      rawName:
                                                                                        "v-show",
                                                                                      value:
                                                                                        _vm.timeSlider >
                                                                                          50 ||
                                                                                        minute ===
                                                                                          1,
                                                                                      expression:
                                                                                        "timeSlider > 50 || minute === 1"
                                                                                    }
                                                                                  ],
                                                                                  key:
                                                                                    minute.index,
                                                                                  style: {
                                                                                    height:
                                                                                      _vm.time_line_height /
                                                                                        6 +
                                                                                      "px",
                                                                                    width:
                                                                                      "100%"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.getTimeText(
                                                                                          time,
                                                                                          minute
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "playlist-events"
                                                                },
                                                                [
                                                                  _c(
                                                                    "ul",
                                                                    _vm._l(
                                                                      _vm.events,
                                                                      function(
                                                                        item,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key: index,
                                                                            staticClass:
                                                                              "playlist-event",
                                                                            style: {
                                                                              top:
                                                                                (_vm.time_line_height /
                                                                                  60) *
                                                                                  item.startTime +
                                                                                14 +
                                                                                "px",
                                                                              height:
                                                                                (_vm.time_line_height /
                                                                                  60) *
                                                                                  (item.endTime -
                                                                                    item.startTime) +
                                                                                "px"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                class: item.active
                                                                                  ? "active"
                                                                                  : "",
                                                                                attrs: {
                                                                                  href:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.onClickItem(
                                                                                      index
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "em",
                                                                                  {
                                                                                    staticClass:
                                                                                      "playlist-event-name"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          item.text
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "3%",
                                                    "max-width":
                                                      "calc(58.3333333333% - 3%)"
                                                  },
                                                  attrs: { cols: "7" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "playlist-group-preview"
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "video-preview",
                                                          attrs: { flat: "" }
                                                        },
                                                        [
                                                          _c("v-card-title", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PREVIEW"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "text-center pb-10 pt-9"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "px-6"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-row"
                                                                    },
                                                                    _vm._l(
                                                                      _vm
                                                                        .contents
                                                                        .division,
                                                                      function(
                                                                        item,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: index,
                                                                            staticClass:
                                                                              "d-flex",
                                                                            style: {
                                                                              width:
                                                                                item +
                                                                                "%"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-carousel",
                                                                              {
                                                                                attrs: {
                                                                                  tag:
                                                                                    "div",
                                                                                  cycle:
                                                                                    "",
                                                                                  interval:
                                                                                    "2500",
                                                                                  "hide-delimiter-background":
                                                                                    "",
                                                                                  "hide-delimiters":
                                                                                    "",
                                                                                  height:
                                                                                    _vm
                                                                                      .lcdItem
                                                                                      .device_type ===
                                                                                    "23"
                                                                                      ? 39.5
                                                                                      : 135,
                                                                                  "show-arrows": false
                                                                                }
                                                                              },
                                                                              _vm._l(
                                                                                JSON.parse(
                                                                                  _vm
                                                                                    .contents[
                                                                                    index
                                                                                  ][0]
                                                                                    .still_shot
                                                                                ),
                                                                                function(
                                                                                  item,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    "v-carousel-item",
                                                                                    {
                                                                                      key: index,
                                                                                      attrs: {
                                                                                        src:
                                                                                          _vm.srcURL +
                                                                                          item,
                                                                                        "reverse-transition":
                                                                                          "fade-transition",
                                                                                        transition:
                                                                                          "fade-transition"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                  _c(
                                                                    "h6",
                                                                    {
                                                                      staticClass:
                                                                        "caption text-left pt-6 pb-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Contents List"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "perfect-scrollbar",
                                                                    {
                                                                      staticStyle: {
                                                                        height:
                                                                          "112px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm.isContents
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex flex-column line-table"
                                                                            },
                                                                            _vm._l(
                                                                              _vm.totalItem,
                                                                              function(
                                                                                items,
                                                                                i
                                                                              ) {
                                                                                return _c(
                                                                                  "div",
                                                                                  {
                                                                                    key: i,
                                                                                    staticClass:
                                                                                      "d-flex flex-row line-table-row"
                                                                                  },
                                                                                  _vm._l(
                                                                                    _vm
                                                                                      .contents
                                                                                      .division,
                                                                                    function(
                                                                                      item,
                                                                                      index
                                                                                    ) {
                                                                                      return _c(
                                                                                        "div",
                                                                                        {
                                                                                          key: index,
                                                                                          staticClass:
                                                                                            "d-flex line-table-item",
                                                                                          style: {
                                                                                            width:
                                                                                              item +
                                                                                              "%"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "width-full"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .contents[
                                                                                                    index
                                                                                                  ][
                                                                                                    i
                                                                                                  ]
                                                                                                    ? _vm
                                                                                                        .contents[
                                                                                                        index
                                                                                                      ][
                                                                                                        i
                                                                                                      ]
                                                                                                        .media_name
                                                                                                    : ""
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  0
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "d-flex justify-center" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          attrs: { text: "", icon: "" },
                                          on: { click: _vm.editPlaylistGroup }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Edit Playlist Group")
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          attrs: { text: "", icon: "" },
                                          on: { click: _vm.closeDetail }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Close")))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tab === "tab-2nd"
                        ? _c(
                            "v-tab-item",
                            {
                              attrs: {
                                value: "tab-2nd",
                                transition: false,
                                "reverse-transition": false
                              }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { column: "" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "5" } },
                                                    [
                                                      _c(
                                                        "h4",
                                                        { staticClass: "mb-4" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "Assigned Product List"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "perfect-scrollbar",
                                                        {
                                                          staticStyle: {
                                                            height: "330px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tbl-type06"
                                                            },
                                                            [
                                                              _c(
                                                                "v-simple-table",
                                                                {
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function() {
                                                                          return [
                                                                            _c(
                                                                              "thead",
                                                                              [
                                                                                _c(
                                                                                  "tr",
                                                                                  [
                                                                                    _c(
                                                                                      "th"
                                                                                    ),
                                                                                    _c(
                                                                                      "th",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-left"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "DESCRIPTION"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "th",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-left"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "PRODUCT ID"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm
                                                                              .articleData
                                                                              .length >
                                                                            0
                                                                              ? _c(
                                                                                  "tbody",
                                                                                  _vm._l(
                                                                                    _vm.articleData,
                                                                                    function(
                                                                                      item,
                                                                                      index
                                                                                    ) {
                                                                                      return _c(
                                                                                        "tr",
                                                                                        {
                                                                                          key: index
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "td",
                                                                                            [
                                                                                              _c(
                                                                                                "v-checkbox",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    value: index
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm.visibleList,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.visibleList = $$v
                                                                                                    },
                                                                                                    expression:
                                                                                                      "visibleList"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _c(
                                                                                            "td",
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  style: {
                                                                                                    "max-width":
                                                                                                      "130px",
                                                                                                    "text-overflow":
                                                                                                      "ellipsis",
                                                                                                    overflow:
                                                                                                      "hidden",
                                                                                                    "white-space":
                                                                                                      "nowrap"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        item
                                                                                                          .article_info
                                                                                                          .article
                                                                                                          .articleName
                                                                                                      ) +
                                                                                                      " "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "td",
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  style: {
                                                                                                    "max-width":
                                                                                                      "130px",
                                                                                                    "text-overflow":
                                                                                                      "ellipsis",
                                                                                                    overflow:
                                                                                                      "hidden",
                                                                                                    "white-space":
                                                                                                      "nowrap"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        item
                                                                                                          .article_info
                                                                                                          .article
                                                                                                          .articleId
                                                                                                      ) +
                                                                                                      " "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  0
                                                                                )
                                                                              : _c(
                                                                                  "tbody",
                                                                                  [
                                                                                    _c(
                                                                                      "tr",
                                                                                      [
                                                                                        _c(
                                                                                          "td",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              "text-align":
                                                                                                "center"
                                                                                            },
                                                                                            attrs: {
                                                                                              colspan:
                                                                                                "3"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "No data available"
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2912659138
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "3%",
                                                        "max-width":
                                                          "calc(58.3333333333% - 3%)"
                                                      },
                                                      attrs: { cols: "7" }
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        { staticClass: "mb-5" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "Selected Product Detail"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "dl",
                                                        {
                                                          staticClass:
                                                            "selected-info mb-5"
                                                        },
                                                        [
                                                          _c("dt", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Template Name"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _vm.articleData
                                                            .length > 0
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .articleData[
                                                                      _vm
                                                                        .templateIndex
                                                                    ].title
                                                                  )
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "selected-box mb-3",
                                                          staticStyle: {
                                                            overflow: "hidden"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "perfect-scrollbar",
                                                            {
                                                              staticStyle: {
                                                                width: "450px",
                                                                height: "200px"
                                                              }
                                                            },
                                                            [
                                                              _c("canvas", {
                                                                attrs: {
                                                                  id: "canvas",
                                                                  width:
                                                                    _vm.canvas
                                                                      .width,
                                                                  height:
                                                                    _vm.canvas
                                                                      .height
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "time-slider",
                                                          style: {
                                                            overflow: "hidden"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center"
                                                            },
                                                            _vm._l(
                                                              _vm.articleData,
                                                              function(
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index,
                                                                    class:
                                                                      index ===
                                                                      _vm.templateIndex
                                                                        ? "timecell-event selected"
                                                                        : "timecell-event",
                                                                    style: _vm.getTemplateStyle(
                                                                      item,
                                                                      index
                                                                    ),
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.selectTemplateProduct(
                                                                          index
                                                                        )
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        {
                                          staticClass: "d-flex justify-center"
                                        },
                                        [
                                          _c("assign-dialog", {
                                            attrs: {
                                              lcdData: _vm.assignLcdItem,
                                              mode: "edit",
                                              btype: "edit",
                                              user: _vm.user
                                            },
                                            on: { fireResetLcd: _vm.resetLcd }
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn",
                                              attrs: { text: "", icon: "" },
                                              on: { click: _vm.closeDetail }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("Close")))]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        [
          _c("v-progress-circular", {
            staticStyle: { width: "100%", overflow: "hidden" },
            attrs: { indeterminate: "", size: 50, width: 7, color: "#ddd" }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300" },
          on: { "click:outside": _vm.fixPreviewClose },
          model: {
            value: _vm.fixPreviewDialog,
            callback: function($$v) {
              _vm.fixPreviewDialog = $$v
            },
            expression: "fixPreviewDialog"
          }
        },
        [
          _c("v-img", {
            attrs: { src: _vm.fixPreviewSrc },
            on: { click: _vm.fixPreviewClose }
          }),
          _c("div", {
            staticClass: "d-flex",
            staticStyle: { position: "relative" },
            style: {
              "background-image": _vm.fixPreviewSrc,
              "line-height": 1.5,
              "justify-content": "center"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }